/** @jsxImportSource @emotion/react */
import { Typography, Link } from '@mui/material'
import { styles } from './windzone.styles'

export default function Clip({ clip, ...props }) {
  return (
    <div {...props}>
      <img
        css={styles.clipImage}
        src={require(`../../../../../lib/common/assets/images/clips/${clip.id}.png`)}
        alt={clip.name}
      />
      {clip.link ? (
        <Link css={styles.clipText} href={clip.link} target="_blank" rel="noopener">
          {clip.name}
        </Link>
      ) : (
        <Typography css={styles.clipText}>{clip.name}</Typography>
      )}
    </div>
  )
}
