const small = 2
const medium = 4
const large = 6

const header = (theme) => ({
  marginBottom: theme.spacing(0.5),
})

const verticalSmall = (theme) => ({
  margin: `${theme.spacing(small)} 0`,
})

const topSmall = (theme) => ({
  margin: `${theme.spacing(small)} 0 0 0`,
})

const bottomSmall = (theme) => ({
  margin: ` 0 0 ${theme.spacing(small)} 0`,
})

const verticalMedium = (theme) => ({
  margin: `${theme.spacing(medium)} 0`,
})

const topMedium = (theme) => ({
  margin: `${theme.spacing(medium)} 0 0 0`,
})

const bottomMedium = (theme) => ({
  margin: ` 0 0 ${theme.spacing(medium)} 0`,
})

const verticalLarge = (theme) => ({
  margin: `${theme.spacing(large)} 0`,
})

const topLarge = (theme) => ({
  margin: `${theme.spacing(large)} 0 0 0`,
})

const bottomLarge = (theme) => ({
  margin: ` 0 0 ${theme.spacing(large)} 0`,
})

export const styles = {
  header,
  verticalSmall,
  topSmall,
  bottomSmall,
  verticalMedium,
  topMedium,
  bottomMedium,
  verticalLarge,
  topLarge,
  bottomLarge,
}
