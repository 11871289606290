const link = { position: 'relative' }

const card = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    alignItems: 'flex-start',
  },
})

const cardImage = (theme) => ({
  display: 'block',
  height: theme.spacing(15),
  margin: '0 auto',
})

const cardButton = (theme) => ({
  marginTop: theme.spacing(1),
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    marginTop: theme.spacing(3),
  },
})

const coveringImage = (theme) => ({
  marginTop: theme.spacing(4),
  textAlign: 'center',
  img: {
    display: 'block',
    margin: '0 auto',
  },
})

export const styles = {
  link,
  card,
  cardImage,
  cardButton,
  coveringImage,
}
