const map = (theme) => ({
  display: 'block',
  margin: '0 auto',
  maxHeight: theme.spacing(50),
  maxWidth: '95%',
  marginTop: theme.spacing(4),
})

const equipmentImage = (theme) => ({
  marginTop: theme.spacing(4),
  textAlign: 'center',
  img: {
    display: 'block',
    margin: '0 auto',
    maxHeight: theme.spacing(22),
  },
})

export const styles = {
  map,
  equipmentImage,
}
