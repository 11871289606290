import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from '@mui/material'
import { content } from './products.content'
import { Section } from '../../../../components'
import { styles } from './products.styles'

export default function CoveringModal({ isOpen, onClose, ...props }) {
  const modal = content.modalCovering
  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpen} onClose={onClose} {...props}>
      <DialogTitle>{modal.headline}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {modal.items.map((item, index) => (
            <Section
              key={`${item.title}-${index}`}
              spacing={index > 0 && 'topMedium'}
              headline={item.title}
            >
              <div key={`${item.name}-${index}`} css={styles.coveringImage}>
                <img src={item.image} alt={item.name} />
                <Typography>{item.text}</Typography>
              </div>
            </Section>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{content.ui.close}</Button>
      </DialogActions>
    </Dialog>
  )
}
