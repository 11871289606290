import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { muiTheme } from '../lib/config/material-ui'
import { FormContextProvider } from './context/form-context'
import { CacheContextProvider } from './context/cache-context'
import { GoogleApiProvider } from './context/google-api-context'
import { AnalyticsContextProvider } from './context/google-analytics-context'
import AppRouter from './router'

function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <CacheContextProvider>
        <FormContextProvider>
          <GoogleApiProvider>
            <AnalyticsContextProvider>
              <AppRouter />
            </AnalyticsContextProvider>
          </GoogleApiProvider>
        </FormContextProvider>
      </CacheContextProvider>
    </ThemeProvider>
  )
}

export default App
