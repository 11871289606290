/** @jsxImportSource @emotion/react */

export default function Icon({ path, size, css, alt, ...props }) {
  return (
    <img
      {...props}
      css={{ width: 'auto', height: `${size}px`, ...css }}
      src={require(`../../../lib/common/assets/images/icons/${path}`)}
      alt={alt}
    />
  )
}
