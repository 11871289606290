const image = {
  margin: '-10px 0',
}

const text = (theme) => ({
  marginLeft: theme.spacing(1),
})

export const styles = {
  image,
  text,
}
