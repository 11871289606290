/** @jsxImportSource @emotion/react */
import { Container } from '@mui/material'

export default function Page({ children, ...props }) {
  return (
    <Container maxWidth="md" {...props}>
      {children}
    </Container>
  )
}
