/** @jsxImportSource @emotion/react */
import { Link } from '@mui/material'
import config from '../../../lib/config/de-at.config.json'
import { content } from './contact.content'
import { styles } from './contact.styles'

const supportProps = config.support

export default function Contact({ color = 'inherit' }) {
  return (
    <table>
      <tr>
        <td>{content.mail}</td>
        <td>
          <Link color={color} href={`mailto:${supportProps.mail}`} css={styles.link}>
            {supportProps.mail}
          </Link>
        </td>
      </tr>
    </table>
  )
}
