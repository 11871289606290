import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from '@mui/material'
import { locales } from '../../../../../lib/common/enums/locales'
import { Section } from '../../../../components'
import { content } from './location.content'

export default function BuildingLocationModal({ locale, isOpen, onClose, ...props }) {
  const modal = content[locale].location.modal
  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpen} onClose={onClose} {...props}>
      <DialogTitle>{modal.headline}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>{modal.locations.text}</Typography>
          {modal.locations.items.map((item, index) => (
            <Section spacing="topMedium" key={`${item.title}-${index}`} headline={item.title}>
              <Typography>{item.text}</Typography>
            </Section>
          ))}
          {locale === locales.germany && (
            <>
              <Typography>{modal.position.text}</Typography>
              {modal.position.items.map((item, index) => (
                <Section spacing="topMedium" key={`${item.title}-${index}`} headline={item.title}>
                  <Typography>{item.text}</Typography>
                </Section>
              ))}
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{content.ui.close}</Button>
      </DialogActions>
    </Dialog>
  )
}
