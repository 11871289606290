export const content = {
  ui: {
    close: 'Schließen',
  },
  headline: 'Angaben zur Schneelastsicherung',
  subline: {
    text: ['Bitte geben Sie die entsprechenden Angaben zur', 'und ', 'an.'],
    link: ['Schneelastzone', 'Scheefangausrüstung', 'Art der Schneesicherung'],
  },
  modals: {
    snowProtectionKind: {
      headline: 'Art der Schneesicherung',
      items: [
        {
          title: 'Sicherung der Verkehrswege',
          text: 'Das Schneefangsystem sichert Verkehrswege vor herabfallenden Schneemassen.',
          image: require('../../../../../lib/common/assets/images/verkehrswege.jpg'),
        },
        {
          title: 'Sicherung der Gebäude-/Dachaufbauten',
          text: 'Ist die direkte Verkehrssicherheit nicht gefährdet, sondern schützt das Schneeschutzsystem tieferliegende Gebäude oder Dachaufbauten, so wird mit einem höheren Teilsicherungsbeiwert gerechnet.',
          image: require('../../../../../lib/common/assets/images/dachaufbauten.jpg'),
        },
      ],
    },
  },
  de: {
    modals: {
      snowCatcherEquipment: {
        headline: 'Schneefangausrüstung',
        systems: {
          title: 'Schneefangsysteme',
          text: 'Es stehen verschiedene Schneefangsysteme zur Auswahl:',
          items: [
            {
              name: 'Schneefanggitter',
              image: require('../../../../../lib/common/assets/images/schneefanggitter.jpg'),
            },
            {
              name: 'Gebirgsschneefang',
              image: require('../../../../../lib/common/assets/images/gebirgsschneefang.jpg'),
            },
            {
              name: 'Rundholz',
              image: require('../../../../../lib/common/assets/images/rundholz.jpg'),
            },
          ],
        },
        snownose: {
          title: 'Schneenasen',
          text: 'Schneenasen werden in einem bestimmten Raster über die ganze Dachfläche verteilt. Die Anzahl der Schneenasen richtet sich nach der Dachneigung und nach der Schneebelastung pro m². Bei einer Dachneigung unter 45° können Schneenasen auch ohne Schneefangsystem verwendet werden.',
          image: require('../../../../../lib/common/assets/images/schneenasen.jpg'),
        },
      },
      snowZone: {
        headline: 'Schneelastzone',
        text: 'Für die Berechnung wird die Schneelastzone, in der das Gebäude erbaut wird, benötigt. Diese wird anhand Ihrer Postleitzahl ermittelt. Bei Geländehöhen oberhalb von 1500 m ist die Vorgabe des Bauherrn als Schneelast direkt einzugeben.',
        image: require('../../../../../lib/common/assets/images/schneelastzonen_de.jpg'),
      },
    },
  },
  at: {
    modals: {
      snowCatcherEquipment: {
        headline: 'Schneefangausrüstung',
        systems: {
          title: 'Schneefangsysteme',
          text: 'Es stehen verschiedene Schneefangsysteme zur Auswahl:',
          items: [
            {
              name: 'Schneefanggitter',
              image: require('../../../../../lib/common/assets/images/schneefanggitter.jpg'),
            },
            {
              name: 'Gebirgsschneefang',
              image: require('../../../../../lib/common/assets/images/gebirgsschneefang.jpg'),
            },
            {
              name: 'Rundholz',
              image: require('../../../../../lib/common/assets/images/rundholz.jpg'),
            },
          ],
        },
        snownose: {
          title: 'Schneenasen',
          text: 'Schneenasen werden in einem bestimmten Raster über die ganze Dachfläche verteilt. Die Anzahl der Schneenasen richtet sich nach der Dachneigung und nach der Schneebelastung pro m². Bei einer Dachneigung unter 45° können Schneenasen auch ohne Schneefangsystem verwendet werden. Bei größerer Steigung muss immer eine Kombination mit einem Schneefangsystem verarbeitet werden. Ab 35° Dachneigung müssen Schneenasen verwendet werden.',
          image: require('../../../../../lib/common/assets/images/schneenasen.jpg'),
        },
      },
      snowZone: {
        headline: 'Schneelastzone',
        text: 'Für die Berechnung wird die Schneelastzone, in der das Gebäude erbaut wird, benötigt. Diese wird anhand Ihrer Postleitzahl ermittelt. Bei Geländehöhen oberhalb von 1500 m ist die Vorgabe des Bauherrn als Schneelast direkt einzugeben.',
        image: require('../../../../../lib/common/assets/images/schneelastzonen_at.jpg'),
      },
    },
  },
}
