import { Link as MuiLink } from '@mui/material'
import { styles } from './link.styles'

export default function Link({ children, ...props }) {
  return (
    <MuiLink {...props} css={styles.link} component="button" type="button" variant="body1">
      {children}
    </MuiLink>
  )
}
