/** @jsxImportSource @emotion/react */
import { useContext } from 'react'
import { Grid, Card, Typography } from '@mui/material'
import { Icon, Section } from '../../../../components'
import { CacheContext } from '../../../../context/cache-context'
import { FormContext } from '../../../../context/form-context'
import { content } from './windsuction.content'
import { styles } from '../step5.styles'

export default function WindSuction(props) {
  const { cache } = useContext(CacheContext)
  const { formState } = useContext(FormContext)

  const calculateAmountStormClips = () => {
    const windSuction = Object.assign({}, cache?.windSuction)
    const amount = Object.keys(windSuction).reduce(function (sum, area) {
      return sum + windSuction[area].stormClips.amount
    }, 0)
    return `${amount} ${content.stormClips[1]}`
  }

  return (
    <div {...props}>
      <Section headline={content.headline}>
        <Card elevation={3}>
          <Grid container css={styles.gridContainer}>
            <Grid item xs={12} sm={4} css={styles.gridItem}>
              <Icon size={70} path={`${formState.roofKind}/Dach.svg`} alt={content.roof} />
            </Grid>
            <Grid item xs={12} sm={4} css={styles.gridItem}>
              <Typography variant="caption">{content.stormClips[0]}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} css={styles.gridItem}>
              <Typography>{calculateAmountStormClips()}</Typography>
            </Grid>
          </Grid>
        </Card>
      </Section>
    </div>
  )
}
