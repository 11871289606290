/** @jsxImportSource @emotion/react */
import creatonLogo from '../../../lib/common/assets/images/creaton-logo.png'
import backgroundImage from '../../../lib/common/assets/images/background.jpg'
import { content } from './header.content'
import { styles } from './header.styles'

export default function Header() {
  return (
    <div css={styles.frame}>
      <img css={styles.background} src={backgroundImage} alt={content.background.meta} />
      <img css={styles.logo} src={creatonLogo} alt={content.logo.meta} />
    </div>
  )
}
