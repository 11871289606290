import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography, Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material'
import { content } from './products.content'
import { Section } from '../../../../components'

export default function RequirementsModal({ isOpen, onClose, ...props }) {
  const modal = content.modalRequirements
  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpen} onClose={onClose} {...props}>
      <DialogTitle>{modal.headline}</DialogTitle>
      <DialogContent>
        <DialogContentText>
            <Section>
                <Typography>{modal.itemWithTable.title}</Typography>
            </Section>
            <Section spacing="topSmall">
                <Typography sx={{fontSize: '0.7rem'}}>{modal.itemWithTable.tableDesc}</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{border: 1, fontWeight: 600}}>{modal.itemWithTable.col1}</TableCell>
                            <TableCell sx={{border: 1, fontWeight: 600}}>{modal.itemWithTable.col2}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {modal.itemWithTable.rows.map((r, index) => <TableRow key={'req-table-row-'+index}>
                            <TableCell sx={{border: 1}}>{r.col1}</TableCell>
                            <TableCell sx={{border: 1}}>{r.col2}</TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </Section>
            <Section spacing="topSmall">
                <Typography sx={{fontSize: '0.7rem'}}>{modal.itemWithTable.hint}</Typography>
            </Section>
          {modal.items.map((item, index) => (
            <Section
              key={`reqs-bullet-point-${index}`}
              spacing={'topSmall'}
            >
              <Typography>{item.text}</Typography>
            </Section>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{content.ui.close}</Button>
      </DialogActions>
    </Dialog>
  )
}
