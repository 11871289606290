export const content = {
  ui: {
    next: 'Weiter',
    close: 'Schließen',
  },
  at: {
    location: {
      headline: 'Lage',
      subline: {
        text: ['Bitte wählen Sie die', 'aus.'],
        link: 'Lage des Gebäudes',
      },
      modal: {
        headline: 'Lage des Gebäudes',
        locations: {
          text: 'Bitte wählen Sie, ob sich das Gebäude in exponierter Lage befindet.',
          items: [
            {
              title: 'Exponierte Lage',
              text: 'Besteht dann, wenn die räumliche Lage des Gebäudes hervorgehoben wird und dadurch einer starken Gefährdung ausgesetzt ist, z.B. Alpine Passlagen, Kamm- und Gipfellagen.',
            },
            {
              title: 'Höhe über Normal Null',
              text: 'Die Höhe wird automatisch passend zum eingegebenem Standort errechnet. Sollte diese merklich von der realen Standort-Höhe abweichen, geben Sie bitte die Adresse genauer an. Dies gilt insbesondere, falls die Standort-Höhe 250 m überschreitet und niedriger berechnet wurde.',
            },
          ],
        },
      },
    },
    regulations: {
      headline: 'Regelwerk',
      subline: {
        text: [
          'Ihr Standort liegt in Österreich. Bitte wählen Sie eine der gültigen',
          'für die Berechnung aus.',
        ],
        link: 'Normen',
      },
      modal: {
        headline: 'Worin unterscheiden sich die beiden Normen?',
        items: [
          {
            title: 'ÖNORM B 3419',
            text: 'Vereinfachte und allgemeine Berechnung mit hohem Sicherheitsfaktor (Worst Case) unabhängig von der Geländeform.',
          },
          {
            title: 'ÖNORM EN 1991-1-4',
            text: 'Genaue und detaillierte Berechnung in Abhängigkeit von Geländeform und Gebäudehöhe (bis max. 25 m). Diese Norm ist bei Standort-Höhen über 250 m oder exponierter Lage Vorschrift.',
          },
        ],
      },
    },
    terrains: {
      headline: 'Gelände',
      subline: {
        text: ['Bitte wählen Sie die entsprechende.', 'aus.'],
        link: 'Geländekategorie',
      },
      modal: {
        headline: 'Lage des Gebäudes',
        items: [
          {
            title: 'Kategorie II',
            text: 'Gebiete mit niedriger Vegetation wie Gras und einzelnen Hindernissen (Bäume, Gebäude) mit Abständen von mindestens der 20-fachen Hindernishöhe.',
          },
          {
            title: 'Kategorie III',
            text: 'Gebiete mit gleichmäßiger Vegetation oder Bebauung oder mit Abständen von weniger als der 20-fachen Hindernishöhe (z.B. Dörfer, vorstädtische Bebauung, Waldgebiete).',
          },
          {
            title: 'Kategorie IV',
            text: 'Gebiete in denen mindestens 15% der Oberfläche mit Gebäuden mit einer mittleren Höhe von 15 m bebaut ist.',
          },
        ],
      },
    },
  },
  de: {
    location: {
      headline: 'Lage',
      subline: {
        text: ['Bitte wählen Sie die', 'aus.'],
        link: 'Lage des Gebäudes',
      },
      modal: {
        headline: 'Lage des Gebäudes',
        locations: {
          text: 'Bitte wählen Sie, ob das Gebäude im Binnenland, an der Küste oder auf einer Nord- bzw. Ostseeinsel liegt.',
          items: [
            {
              title: 'Küste',
              text: 'Als Küste gelten auch Bereiche an einem See mit mindestens 5 km freier Fläche in Windrichtung bis 5 km landeinwärts.',
            },
            {
              title: 'Höhe über Normal Null',
              text: 'Die Höhe wird automatisch passend zum eingegebenem Standort errechnet. Sollte diese merklich von der realen Standort-Höhe abweichen, geben Sie bitte die Adresse genauer an. Dies gilt insbesondere, falls die Standort-Höhe 250 m überschreitet und niedriger berechnet wurde.',
            },
          ],
        },
        position: {
          text: 'Wählen Sie zudem, ob nachfolgende Punkte auf die Gebäudelage zutreffen.',
          items: [
            {
              title: 'Norddeutsche Tiefebene',
              text: 'Die Norddeutsche Tiefebene bezeichnet einen der Landschaftsgroßräume in Deutschland, der im Norden von den Küsten der Nord- und Ostsee sowie im Süden von der mitteleuropäischen Mittelgebirgsschwelle begrenzt wird.',
            },
            {
              title: 'Exponierte Lage',
              text: 'Besteht dann, wenn die räumliche Lage des Gebäudes hervorgehoben wird und dadurch einer starken Gefährdung ausgesetzt ist, z.B. Alpine Passlagen, Kamm- und Gipfellagen.',
            },
          ],
        },
      },
    },
  },
}
