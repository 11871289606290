/** @jsxImportSource @emotion/react */
import { Formik, Form } from 'formik'
import {useContext, useEffect, useState} from 'react'
import * as yup from 'yup'
import { FormikNavigation, Section } from '../../../components'
import { FormContext } from '../../../context/form-context'
import { CacheContext } from '../../../context/cache-context'
import { useUnderRoofCalculationApi } from '../../../hooks/use-api'
import Products from './products/products'
import { content } from './step3.content'
import { locales } from '../../../../lib/common/enums/locales'
import PitchTileInfoModal from './products/pitch-tile-info-modal'
import isLowRoofPitch from "../../../../lib/utils/is-low-roof-pitch";

const validationSchema = yup.object().shape({
  tile: yup.object().required(content.errors.required),
  roofCovering: yup.string().required(content.errors.required),
})

const initialValues = {
  tile: '',
  roofCovering: '',
  higherRequirements: '0',
  guideline: '',
}

export default function Step3({ nextStep, goBack, ...props }) {
  const { formState, setFormState } = useContext(FormContext)
  const { cache } = useContext(CacheContext)
  const [isConfigurationError, setConfigurationError] = useState(false)
  const [lowRoofPitch, setLowRoofPitch] = useState(false)

  useEffect(() => {
    if(isLowRoofPitch(formState?.dimensions?.roofPitch)) {
      if(!lowRoofPitch) {
        setLowRoofPitch(true);
      }
    } else if(lowRoofPitch) {
      setLowRoofPitch(false);
    }
  }, [formState?.dimensions?.roofPitch, lowRoofPitch]);

  const { mutate: calculate } = useUnderRoofCalculationApi({
    onSuccess: () => {
      nextStep()
    },
    onError: () => {
      setConfigurationError(true)
    },
  })

  const handleSubmit = (values) => {
    const newState = { ...formState, ...values }
    setFormState(newState)



    if (formState.place.locale === locales.austria) {
      nextStep()
    } else {
      calculate({ cache, ...newState })
    }
  }

  return (
    <div {...props}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Products lowRoofPitch={lowRoofPitch} />
          <Section spacing="verticalLarge">
            <FormikNavigation onBack={() => goBack()} />
          </Section>
        </Form>
      </Formik>

      {/* MODALS */}
      <PitchTileInfoModal
        isOpen={isConfigurationError}
        onClose={() => setConfigurationError(false)}
      />
    </div>
  )
}
