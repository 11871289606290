const step = {
  padding: '0 15px',
}

const page = (theme) => ({
  marginTop: `-${theme.spacing(12)}`,
})

const cloud = (theme) => ({
  marginBottom: theme.spacing(6),
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    marginBottom: theme.spacing(7),
  },
})

export const styles = {
  step,
  page,
  cloud,
}
