import { Checkbox, FormControlLabel } from '@mui/material'
import { getIn } from 'formik'

/**
 * Helper Component to bind a material ui checkbox component with formik
 */
export default function FormikCheckbox({ formik, name, ...props }) {
  return (
    <FormControlLabel
      {...props}
      control={
        <Checkbox
          fullWidth
          name={name}
          checked={getIn(formik.values, name)}
          value={getIn(formik.values, name)}
          onChange={formik.handleChange}
        />
      }
    />
  )
}
