/** @jsxImportSource @emotion/react */
import { useContext, useState } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useLocation } from 'react-router'
import { Contact, FormikNavigation, Section, Alert } from '../../../components'
import { FormContext } from '../../../context/form-context'
import { CacheContext } from '../../../context/cache-context'
import { AnalyticsContext } from '../../../context/google-analytics-context'
import { usePDFApi } from '../../../hooks/use-api'
import { content } from './step5.content'
import PDF from './pdf/pdf'
import WindSuction from './windsuction/windsuction'
import SnowLoad from './snowload/snowload'
import SnowNose from './snownose/snownose'
import Success from './success/success'

const validationSchema = yup.object().shape({
  email: yup.string().email(content.errors.mail).required(content.errors.required),
  name: yup.string().required(content.errors.required),
  targetGroup: yup.string().required(content.errors.required),
})

const initialValues = {
  email: '',
  name: '',
  targetGroup: '',
  legalHints: true,
}

export default function Step5({ goBack, nextStep, goToStart, ...props }) {
  const location = useLocation()
  const [isSuccess, setSuccess] = useState(false)
  const { analytics } = useContext(AnalyticsContext)
  const { cache } = useContext(CacheContext)
  const { formState, setFormState } = useContext(FormContext)
  const {
    isError,
    isLoading,
    reset: restPDFState,
    mutate: getPDF,
    errorMessage,
  } = usePDFApi({
    onSuccess: (response) => {
      let blob = new Blob([response[0]], { type: 'application/pdf' })
      let objectUrl = URL.createObjectURL(blob)
      let link = document.createElement('a')
      link.href = objectUrl
      link.setAttribute('download', 'CREATON_Ergebnis')
      document.body.appendChild(link)
      link.click()
      setSuccess(true)
      analytics.eventPDF()
    },
  })

  const handleSubmit = (values) => {
    restPDFState()
    const newState = { ...formState, ...values }
    setFormState(newState)
    getPDF({ cache, ...newState })
  }

  const handleBack = () => {
    restPDFState()
    setSuccess(false)
    goBack()
  }

  const handleCustomize = () => {
    restPDFState()
    setSuccess(false)
    goToStart()
  }

  const backToStart = () => {
    window.location.href = location.pathname
  }

  return (
    <div {...props}>
      <WindSuction />
      {cache?.snowLoadProtection && (
        <>
          <SnowLoad />
          {cache?.snowLoadProtection?.snowNoses && <SnowNose />}
        </>
      )}

      {/* SUPPORT */}
      <Section
        spacing="topLarge"
        headline={content.support.headline}
        subline={content.support.subline}
      >
        <Contact color="primary" />
      </Section>

      {/* PDF */}
      {!isSuccess ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <PDF />

            {/* ALERT */}
            {isError && (
              <Section spacing="topMedium">
                <Alert severity="error" title={content.alerts.pdf} error={errorMessage}>
                  <Contact />
                </Alert>
              </Section>
            )}

            <Section spacing="verticalLarge">
              <FormikNavigation
                onBack={handleBack}
                submitLabel={content.ui.next}
                loading={isLoading}
              />
            </Section>
          </Form>
        </Formik>
      ) : (
        <Success onBack={backToStart} onCustomize={handleCustomize} />
      )}
    </div>
  )
}
