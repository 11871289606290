export const content = {
  headline: 'Berechnungs­hinweise',
  ui: {
    close: 'Schließen',
  },
  modal: {
    hints: [
      'Die Sturmklammer- und die Schneelastberechnungen in Deutschland basieren auf der Fachinformation „Windlasten auf Dächern mit Dachziegeln“ bzw. auf der Anpassung des Regelwerks zur Lastenermittlung von Schneelasten an die DIN EN 1991, sowie aus dem „Merkblatt - Einbauteile bei Dachdeckungen“. Diese sind Bestandteil des Regelwerks, welches vom Zentralverband des deutschen Dachdeckerhandwerks ausgegeben wird. Ihre Gültigkeit besitzen das Regelwerk und die Fachinformation.',
      'Die Sturmklammer- und Schneelastberechnungen in Österreich basieren auf den Berechnungsgrundlagen der ÖNORM EN 1991-1-4 und der ÖNORM B 3419 bzw. der ÖNORM EN 1991-1-3 und ÖNORM B 1991-1-3. Mit dem Erscheinen der ÖNORM B 3419 wurden auch die Vorgaben des Eurocodes ÖNORM B 1991-1-4 für die Berechnung des Windsoges mit eingearbeitet. Die Schneelastberechnung beläuft sich auf jeweils eine Dachseite. Besonderheiten wie z.B. Verwehungen, Höhenversatz oder Aufbauten werden nicht berücksichtigt. Diese Fälle müssen nach ÖNORM gesondert gerechnet werden.',
      'Aus der Berechnungsgrundlage der Fachinformation sowie des Regelwerks bzw. der ÖNORM und Ihren Angaben über das Bauvorhaben ermittelt das Berechnungs- und Planungstool von CREATON die Verklammerung bzw. notwendigen Schneeschutzsysteme auf diesem Dach. Das Ergebnis wird in Tabellenform ausgegeben. Die CREATON GmbH legt höchsten Wert auf bestmögliche Aussagen und Ergebnisse, dennoch ist es systembedingt nicht möglich, alle denkbaren Dachtypen und Anwendungsfälle darzustellen. Grundlage für die Berechnung sind die Auszugswerte der nach EN 14437 getesteten Klammer-Ziegel-Kombinationen bzw. der getesteten Schneehalte- und Schneefangsysteme der CREATON GmbH. Das Ergebnis kann somit nicht auf andere Sturmklammern, Befestigungsmittel oder Schneeschutzsysteme übertragen werden.',
      'Nehmen Sie darum bitte zur Kenntnis, dass es sich bei dem Berechnungs- und Planungstool von CREATON um eine kostenlose Internetbasierte Berechnungshilfe zur Windsog-Sicherung handelt, die sich auf grundlegende Dachgrundtypen beschränken muss und keine verbindlichen Erklärungen oder Empfehlungen der CREATON GmbH darstellt. Eine individuelle, verbindliche Planung, die die Besonderheiten des jeweiligen Einzelfalls berücksichtigt, kann dadurch nicht ersetzt werden. So können beispielsweise Berechnungen für Gebäude in exponierten Lagen nicht von unserem Programm durchgeführt werden.',
      'Die Haftung für Schäden jeder Art sowie sonstige Ansprüche, gleich welcher Art und welchen Rechtsgrundes, die sich aus der Nutzung dieser Applikation ergeben, wird - soweit gesetzlich zulässig - ausgeschlossen. Mit der Nutzung der Schneelast- und Sturmklammerberechnung erkennen Sie den Haftungsausschluss uneingeschränkt an. Unsere Haftung für Vorsatz und grobe Fahrlässigkeit sowie Ansprüche nach dem Produkthaftungsgesetz gegen uns werden hiervon nicht berührt.',
    ],
    contact: {
      text: 'Wenn Sie weitere Fragen haben, kontaktieren Sie uns bitte. Nutzen Sie hierzu unser',
      link: {
        text: 'Kontaktformular',
        meta: 'Zum CREATON Kontaktformular',
        href: 'https://www.creaton.de/kontakt',
      },
    },
  },
}
