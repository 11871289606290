import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LandingPage from './pages/landing-page/landing-page'
import ToolPage from './pages/tool-page/tool-page'

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/tool" element={<ToolPage />} />
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </BrowserRouter>
  )
}
