import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import { Section, FormikSelect } from '../../../../components'
import { content } from './type.content'
import config from '../../../../../lib/config/de-at.config.json'

export default function Type(props) {
  const formik = useFormikContext()

  return (
    <div {...props}>
      <Section spacing="topLarge" headline={content.headline}>
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid item xs={12} sm={6}>
            <FormikSelect
              formik={formik}
              name="buildingType"
              label={content.ui.buildingType}
              options={config.building.properties.buildingTypes}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikSelect
              formik={formik}
              name="buildingProject"
              label={content.ui.buildingProject}
              options={config.building.properties.buildingProjects}
              required
            />
          </Grid>
        </Grid>
      </Section>
    </div>
  )
}
