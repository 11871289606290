/** @jsxImportSource @emotion/react */
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Link,
} from '@mui/material'
import { Section } from '../../components'
import { content } from './calculaction-info.content'
import { styles } from './calculation-info.styles'

export default function CalculationInfoModal({ isOpen, onClose, ...props }) {
  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpen} onClose={onClose} {...props}>
      <DialogTitle>{content.headline}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Section>
            {content.modal.hints.map((hint, index) => (
              <Typography key={`hint-${index}`} css={index > 0 && styles.paragraph}>
                {hint}
              </Typography>
            ))}
            <Typography css={styles.paragraph}>
              {content.modal.contact.text}{' '}
              <Link
                target="_blank"
                rel="noopener"
                href={content.modal.contact.link.href}
                title={content.modal.contact.link.meta}
              >
                {content.modal.contact.link.text}
              </Link>
              .
            </Typography>
          </Section>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{content.ui.close}</Button>
      </DialogActions>
    </Dialog>
  )
}
