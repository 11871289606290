/**
 * This file provides all functions defined in api.js as React hooks.
 *
 * The hooks mainly do the following:
 * - Wrap the calls to api.js using helper: _useQuery, _useMutation; inspired by react-query library
 * - Return states before, while and after a request
 * - Apply optional options for each individual api endpoint.
 * - Save response to CacheContext for global access
 */
import { useContext, useEffect, useState } from 'react'
import { CacheContext } from '../context/cache-context'
import { tileKinds } from '../../lib/common/enums/tile-kinds'
import { api as productionApi } from '../../lib/utils/api'
import { mockApi } from '../../lib/utils/mock-api'
import { print } from '../../lib/utils/console'

/**
 * Use MockApi for testing and development
 */
let api = process.env.REACT_APP_USE_MOCKS === 'true' ? mockApi : productionApi

/**
 * Helper for POST, PUT, PATCH and DELETE requests
 */
const _useMutation = ({ requests, onSuccess, onError }) => {
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [data, setData] = useState()

  const reset = () => {
    setLoading(false)
    setError(false)
    setErrorMessage()
    setData()
  }

  const mutate = async (params) => {
    setLoading(true)
    Promise.all(requests.map((request) => request(params)))
      .then((response) => {
        Boolean(onSuccess) && onSuccess(response)
        setLoading(false)
      })
      .catch((error) => {
        print(error, 'error')
        Boolean(onError) && onError(error)
        setErrorMessage(error.message)
        setError(true)
        setLoading(false)
      })
  }

  return { data, setData, isLoading, isError, errorMessage, reset, mutate }
}

/**
 * Helper for GET requests
 */
const _useQuery = ({ requests, enabled, onSuccess }) => {
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [data, setData] = useState()

  const reset = () => {
    setLoading(false)
    setError(false)
    setErrorMessage()
    setData()
  }

  useEffect(() => {
    if (enabled() && !isLoading && !isError) {
      setLoading(true)
      Promise.all(requests.map((request) => request()))
        .then((response) => {
          Boolean(onSuccess) && onSuccess(response)
          setLoading(false)
        })
        .catch((error) => {
          print(error, 'error')
          setErrorMessage(error.message)
          setError(true)
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, isLoading, isError])

  return { data, setData, isLoading, isError, errorMessage, reset }
}

/**
 * API Hooks
 */
export const useTileKindsApi = ({ enabled }) => {
  const { cache, setCache } = useContext(CacheContext)
  const { setData, ...rest } = _useQuery({
    enabled: () => enabled && !cache.tiles,
    requests: [() => api.getTiles(tileKinds.clay), () => api.getTiles(tileKinds.stone)],
    onSuccess: (values) => {
      const tiles = [...values[0], ...values[1]]
      setData(tiles)
      setCache({ ...cache, tiles })
    },
  })
  return rest
}

export const useZonesApi = ({ params, enabled, onSuccess }) => {
  const { cache, setCache } = useContext(CacheContext)
  const { setData, ...rest } = _useQuery({
    enabled: () => enabled && !cache.windZoneDlubal && !cache.snowZoneDlubal,
    requests: [() => api.getSnowZone(params), () => api.getWindZone(params)],
    onSuccess: (values) => {
      const snowZoneDlubal = values[0]
      const windZoneDlubal = values[1]
      setData({ snowZoneDlubal, windZoneDlubal })
      setCache({ ...cache, windZoneDlubal, snowZoneDlubal })
      onSuccess({ windZoneDlubal, snowZoneDlubal })
    },
  })
  return rest
}

export const useRoofValidationApi = (options) => {
  return _useMutation({
    requests: [api.postRoofValidate],
    ...options,
  })
}

export const useUnderRoofCalculationApi = ({ onSuccess, ...options }) => {
  const { cache, setCache } = useContext(CacheContext)
  const requests = [api.postUnderRoofCalculation]
  return _useMutation({
    requests: requests,
    onSuccess: (response) => {
      const underRoofCalculation = response[0]
      setCache({ ...cache, underRoofCalculation })
      onSuccess({ underRoofCalculation })
    },
    onError: (error) => {},
    ...options,
  })
}

export const useWindSuctionAndSnowLoadProtectionCalculationApi = ({
  snowCalculation,
  onSuccess,
  ...options
}) => {
  const { cache, setCache } = useContext(CacheContext)
  const requests = snowCalculation
    ? [api.postWindSuctionCalculation, api.postSnowLoadProtectionCalculation]
    : [api.postWindSuctionCalculation]
  return _useMutation({
    requests: requests,
    onSuccess: (response) => {
      setCache({ ...cache, windSuction: response[0], snowLoadProtection: response[1] })
      onSuccess(response)
    },
    ...options,
  })
}

export const usePDFApi = (options) => {
  return _useMutation({
    requests: [api.postPDF],
    ...options,
  })
}
