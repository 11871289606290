/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { Formik, Form } from 'formik'
import { useContext } from 'react'
import * as yup from 'yup'
import { isEqual, isNil } from 'lodash'
import { Contact, FormikNavigation, Section, Alert } from '../../../components'
import { FormContext } from '../../../context/form-context'
import { CacheContext } from '../../../context/cache-context'
import { AnalyticsContext } from '../../../context/google-analytics-context'
import WindSuctionAT from './windsuction/windsuction-at'
import WindSuctionDE from './windsuction/windsuction-de'
import SnowLoad from './snowload/snowload'
import { locales } from '../../../../lib/common/enums/locales'
import config from '../../../../lib/config/de-at.config.json'
import { content } from './step4.content'
import { useWindSuctionAndSnowLoadProtectionCalculationApi } from '../../../hooks/use-api'

const snowGroundLoadProps = config.protection.properties.snowGroundLoad

const validationSchema = (formState) => {
  return yup.mixed().when({
    is: () => Boolean(formState.tile?.supportSnowCalculation),
    then: yup.object().shape({
      windZone: yup.mixed().when({
        is: () => formState.place.locale === locales.germany,
        then: yup.string().required(content.errors.required),
      }),
      clip: yup.object().required(content.errors.required),
      snowZone: yup.string().required(content.errors.required),
      snowGroundLoad: yup
        .number()
        .min(0, snowGroundLoadProps.error)
        .max(1500, snowGroundLoadProps.error)
        .required(content.errors.required),
      snowCatcherEquipmentKind: yup.string().required(content.errors.required),
      snowProtectionKind: yup.string().required(content.errors.required),
    }),
    otherwise: yup.object().shape({
      windZone: yup.mixed().when({
        is: () => formState.place.locale === locales.germany,
        then: yup.string().required(content.errors.required),
      }),
      clip: yup.object().required(content.errors.required),
    }),
  })
}

const initialValues = {
  windZone: '',
  clip: '',
  snowZone: '',
  snowGroundLoad: '',
  snowCatcherEquipmentKind: '',
  snowProtectionKind: '',
  isSnowNose: false,
}

export default function Step4({ nextStep, goBack, ...props }) {
  const [formikValues, setFormikValues] = useState()
  const [isCalculationError, setCalculationError] = useState(false)
  const { analytics } = useContext(AnalyticsContext)
  const { formState, setFormState } = useContext(FormContext)
  const { cache } = useContext(CacheContext)
  const {
    isLoading,
    reset,
    mutate: calculate,
    errorMessage,
  } = useWindSuctionAndSnowLoadProtectionCalculationApi({
    snowCalculation: Boolean(formState.tile?.supportSnowCalculation),
    onSuccess: () => {
      analytics.eventCalculation()
      nextStep()
    },
    onError: () => {
      setCalculationError(true)
    },
  })

  /**
   * Reset errors if form fields change
   */
  const handleChanges = (values) => {
    setFormikValues(values)
    if (isNil(values) || isNil(formikValues)) return
    if (isEqual(values, formikValues)) return
    if (!isCalculationError) return
    setCalculationError(false)
    reset()
  }

  const handleSubmit = (values) => {
    const newState = { ...formState, ...values }
    setFormState(newState)
    calculate({ cache, ...newState })
  }

  return (
    <div {...props}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(formState)}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          handleChanges(formik?.values)
          return (
            <Form>
              {formState.place.locale === locales.germany && <WindSuctionDE />}
              {formState.place.locale === locales.austria && <WindSuctionAT />}
              {Boolean(formState.tile?.supportSnowCalculation) && <SnowLoad />}

              {/* ALERTS */}
              {isCalculationError && (
                <Section spacing="topLarge">
                  <Alert severity="error" title={content.errors.calculation} error={errorMessage}>
                    <Contact />
                  </Alert>
                </Section>
              )}

              <Section spacing="verticalLarge">
                <FormikNavigation
                  onBack={() => goBack()}
                  submitLabel={content.ui.next}
                  loading={isLoading}
                />
              </Section>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
