export const content = {
  ui: {
    next: 'PDF downloaden',
  },
  errors: {
    required: 'Eingabe erforderlich',
    mail: 'Ungültige E-Mail-Ad­res­se',
  },
  alerts: {
    pdf: 'Beim Erstellen des PDFs ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder melden Sie sich bei unserer Anwendungstechnik:',
  },
  support: {
    headline: 'Support',
    subline: 'Bei weiteren Fragen steht Ihnen unsere Anwendungstechnik gerne zur Verfügung:',
  },
}
