import { createContext, useState } from 'react'
import { locales } from '../../lib/common/enums/locales'
import { regulations } from '../../lib/common/enums/regulations'
import { roofKinds } from '../../lib/common/enums/roof-kinds'

/**
 * Provides global form state.
 */
const initialState = {
  place: {
    locale: locales.germany,
  },
  location: {
    regulation: regulations.DEFAULT_DE,
  },
  roofKind: roofKinds.saddle,
  isUnderRoof: true,
}

const FormContext = createContext(initialState)

const FormContextProvider = (props) => {
  const [formState, setFormState] = useState(initialState)

  const resetFormState = () => {
    setFormState(initialState)
    return initialState
  }

  return (
    <FormContext.Provider value={{ formState, setFormState, resetFormState }}>
      {props.children}
    </FormContext.Provider>
  )
}

export { FormContext, FormContextProvider }
