/** @jsxImportSource @emotion/react */
import { useContext } from 'react'
import { Grid, Card, Typography } from '@mui/material'
import { Icon, Section } from '../../../../components'
import { CacheContext } from '../../../../context/cache-context'
import { FormContext } from '../../../../context/form-context'
import { content } from './snowload.content'
import { styles } from '../step5.styles'

export default function SnowLoad(props) {
  const { cache } = useContext(CacheContext)
  const { formState } = useContext(FormContext)

  return (
    <div {...props}>
      <Section spacing="topLarge" headline={content.headline}>
        <Card elevation={3}>
          <Grid container css={styles.gridContainer}>
            <Grid item xs={12} sm={4} css={styles.gridItem}>
              <Icon
                size={70}
                path={`${formState.roofKind}/Schneefangreihen.svg`}
                alt={content.roof}
              />
            </Grid>
            <Grid item xs={12} sm={4} css={styles.gridItem}>
              <Typography variant="caption">{content.snowCatcherRows[0]}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} css={styles.gridItem}>
              <Typography>
                {Boolean(cache?.snowLoadProtection?.secondary?.snowCatcherRows)
                  ? `${cache?.snowLoadProtection?.primary?.snowCatcherRows} | ${cache?.snowLoadProtection?.secondary?.snowCatcherRows} ${content.snowCatcherRows[1]}`
                  : `${cache?.snowLoadProtection?.primary?.snowCatcherRows} ${content.snowCatcherRows[1]}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            css={(theme) => ({
              ...styles.gridContainer(theme),
              background: theme.palette.grey[50],
            })}
          >
            <Grid item xs={12} sm={4} css={styles.gridItem}>
              <Icon size={70} path={`${formState.roofKind}/Stützen.svg`} alt={content.roof} />
            </Grid>
            <Grid item xs={12} sm={4} css={styles.gridItem}>
              <Typography variant="caption">{content.supports[0]}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} css={styles.gridItem}>
              <Typography>{`${
                cache?.snowLoadProtection?.primary?.supports +
                (cache?.snowLoadProtection?.secondary?.supports ?? 0)
              } ${content.supports[1]}`}</Typography>
            </Grid>
          </Grid>
        </Card>
      </Section>
    </div>
  )
}
