export const content = {
  headline: 'Gebäudestandort',
  subline:
    'Bitte geben Sie die vollständige Adresse mit PLZ, Ort, Straße und Hausnummer an. Nur so kann die genaue Höhenlage bestimmt werden.',
  autocomplete: 'Adresse oder Postleitzahl',
  noOptions: 'Keine Ergebnisse',
  ui: {
    next: 'Weiter',
    close: 'Schließen',
  },
}
