/** @jsxImportSource @emotion/react */
import { styles } from './cloud.styles'

export default function Cloud({ text, ...props }) {
  return (
    <div {...props}>
      <div css={styles.cloudWrapper}>
        <h1>
          <span css={styles.cloudSegmentTop}>{text[0]}</span>
          <span css={styles.cloudSegmentCenter}>{text[1]}</span>
          {text[2] && <span css={styles.cloudSegmentBottom}>{text[2]}</span>}
        </h1>
      </div>
    </div>
  )
}
