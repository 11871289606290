const cloudWrapper = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  h1: {
    margin: 0,
    padding: 0,
  },
})

const cloudSegment = (theme) => ({
  display: 'block',
  background: theme.palette.common.black,
  color: 'white',
  width: 'fit-content',
  textTransform: 'uppercase',
  fontWeight: 'bold',
})

const cloudSegmentTop = (theme) => ({
  ...cloudSegment(theme),
  padding: '0 5px',
  marginLeft: '10%',
  fontSize: '0.85rem',
})

const cloudSegmentCenter = (theme) => ({
  ...cloudSegment(theme),
  padding: '0 8px',
  fontSize: '1.5rem',
})

const cloudSegmentBottom = (theme) => ({
  ...cloudSegment(theme),
  padding: '0 3px',
  marginRight: '20%',
  fontSize: '0.7rem',
  float: 'right',
})

export const styles = {
  cloudWrapper,
  cloudSegment,
  cloudSegmentTop,
  cloudSegmentCenter,
  cloudSegmentBottom,
}
