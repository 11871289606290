/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { Typography, Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import { content } from './location.content'
import configDE from '../../../../../lib/config/de.config.json'
import config from '../../../../../lib/config/de-at.config.json'
import BuildingLocationModal from './building-location-modal'
import { locales } from '../../../../../lib/common/enums/locales'
import { Section, FormikSelect, FormikCheckbox, Link } from '../../../../components'
import { regulations } from '../../../../../lib/common/enums/regulations'

const altitudeProps = config.location.properties.altitude
const buildingLocationProps = configDE.location.properties.buildingLocations
const locations = configDE.location.properties.locations

export default function LocationDE(props) {
  const { values, errors, touched, ...formik } = useFormikContext()
  const [isLocationModalOpen, setLocationModalOpen] = useState(false)
  const locale = locales.germany

  /**
   * Automatic selection of the regulation, since only one standard is available
   */
  useEffect(() => {
    formik.setFieldValue('location.regulation', regulations.DEFAULT_DE, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div {...props}>
      {/* LOCATION */}
      <Section
        spacing="topLarge"
        headline={content[locale].location.headline}
        subline={
          <>
            <span>{content[locale].location.subline.text[0]}</span>{' '}
            <Link onClick={() => setLocationModalOpen(true)}>
              {content[locale].location.subline.link}
            </Link>{' '}
            <span>{content[locale].location.subline.text[1]}</span>
          </>
        }
      >
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid item xs={12} sm={6}>
            <FormikSelect
              formik={{ values, errors, touched, ...formik }}
              name={`location.${buildingLocationProps.id}`}
              label={buildingLocationProps.desc}
              options={buildingLocationProps.items}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption" color="info">
              {altitudeProps.desc}
            </Typography>
            <Typography variant="body1">
              {values.place.altitude} {altitudeProps.unit}
            </Typography>
          </Grid>
        </Grid>
      </Section>

      {/* LOCATION SPECIFICS */}
      <Section spacing="topSmall">
        <Grid container spacing={{ xs: 0, sm: 4 }}>
          <Grid item xs={12} sm={6}>
            <FormikCheckbox
              formik={{ values, errors, touched, ...formik }}
              name={`location.${locations[0].id}`}
              label={locations[0].desc}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikCheckbox
              formik={{ values, errors, touched, ...formik }}
              name={`location.${locations[1].id}`}
              label={locations[1].desc}
            />
          </Grid>
        </Grid>
      </Section>

      {/* MODALS */}
      <BuildingLocationModal
        locale={locale}
        isOpen={isLocationModalOpen}
        onClose={() => setLocationModalOpen(false)}
      />
    </div>
  )
}
