import ReactGA from 'react-ga'

/**
 * This file provides the google analytics instance and all events.
 */
export default class GoogleAnalytics {
  constructor() {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)
  }

  eventCalculation() {
    if (process.env.NODE_ENV === 'development') return
    ReactGA.event({
      category: 'Berechnungen',
      action: 'Clicked: Zum Ergebnis',
    })
  }

  eventPDF() {
    if (process.env.NODE_ENV === 'development') return
    ReactGA.event({
      category: 'Ergebnis',
      action: 'Clicked: PDF erhalten',
    })
  }
}
