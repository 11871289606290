/** @jsxImportSource @emotion/react */
import { Fragment, useState } from 'react'
import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import { Section, FormikSelect, Link, Icon } from '../../../../components'
import { content } from './specifications.content'
import config from '../../../../../lib/config/de-at.config.json'
import configDE from '../../../../../lib/config/de.config.json'
import { locales } from '../../../../../lib/common/enums/locales'
import RoofStructureModal from './roof-structure-modal'
import { styles } from './specifications.styles'

const roofKindProps = config.building.properties.roofKinds
const roofStructureProps = configDE.building.properties.roofStructure

export default function SpecificationDE(props) {
  const [isRoofStructureModalOpen, setRoofStructureModalOpen] = useState(false)
  const formik = useFormikContext()
  const locale = locales.germany

  return (
    <div {...props}>
      <Section
        spacing="topLarge"
        headline={content[locale].headline}
        subline={
          <>
            <span>{content[locale].subline.text[0]}</span>{' '}
            <Link onClick={() => setRoofStructureModalOpen(true)}>
              {content[locale].subline.link}
            </Link>{' '}
            <span>{content[locale].subline.text[1]}</span>
          </>
        }
      >
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid item xs={12} sm={6}>
            <FormikSelect
              formik={formik}
              name={roofKindProps.id}
              label={roofKindProps.desc}
              options={roofKindProps.items.map((type) => ({
                id: type.id,
                desc: (
                  <Fragment key={type.id}>
                    <Icon
                      size={32}
                      css={styles.image}
                      alt={type.id}
                      path={`${type.id}/${type.icon}`}
                    />
                    <span css={styles.text}>{type.desc}</span>
                  </Fragment>
                ),
              }))}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikSelect
              formik={formik}
              name={roofStructureProps.id}
              label={roofStructureProps.desc}
              options={roofStructureProps.items}
              required
            />
          </Grid>
        </Grid>
      </Section>

      {/* MODALS */}
      <RoofStructureModal
        locale={locale}
        isOpen={isRoofStructureModalOpen}
        onClose={() => setRoofStructureModalOpen(false)}
      />
    </div>
  )
}
