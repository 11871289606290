export const content = {
  ui: {
    next: 'Zum Ergebnis',
  },
  errors: {
    required: 'Eingabe erforderlich',
    calculation:
      'Fehler bei der Berechnung von Windsogsicherung und/oder Schneelastsicherung. Bitte überprüfen Sie ihre Eingaben oder melden sich bei unserer Anwendungstechnik:',
  },
}
