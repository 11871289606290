import { createContext, useState } from 'react'

/**
 * Provides global async state.
 */
const initialState = {}
const CacheContext = createContext(initialState)

const CacheContextProvider = (props) => {
  const [cache, setCache] = useState(initialState)

  const resetCache = () => {
    setCache(initialState)
    return initialState
  }

  return (
    <CacheContext.Provider value={{ cache, setCache, resetCache }}>
      {props.children}
    </CacheContext.Provider>
  )
}

export { CacheContext, CacheContextProvider }
