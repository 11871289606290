export const content = {
  ui: {
    close: 'Schließen',
  },
  headline: 'Angaben zur Windsogsicherung',
  subline: {
    text: ['Bitte geben Sie die entsprechende', 'und', 'an.'],
    link: ['Windzone', 'Klammerart'],
  },
  modals: {
    clipKinds: {
      headline: 'Sturmklammern',
      standard: {
        title: 'Standard',
        text: 'Die Einhänge- und Einschlagklammern werden standardmäßig für alle CREATON Tondachziegel- bzw. Betondachsteinmodelle angeboten. Die Einhängeklammern sind für die Lattungen 30x50mm, 40x50mm oder 40x60mm erhältlich. Die Einschlagklammern sind universell bei den verschiedenen Lattungen einsetzbar. (EHK = Einhängeklammer, ESK = Einschlagklammer)',
        items: [
          {
            id: 'EHK_Tondachziegel',
            name: 'EHK Tondachziegel',
            link: 'https://www.creaton.de/fileadmin/user_upload/09_DOWNLOAD/Fachleute/Montageanleitungen/Sturmklammern/Montage_Einhaengeklammer.pdf',
          },
          {
            id: 'ESK_Tondachziegel',
            name: 'ESK Tondachziegel',
            link: 'https://www.creaton.de/fileadmin/user_upload/09_DOWNLOAD/Fachleute/Montageanleitungen/Sturmklammern/Montage_Einschlagklammer.pdf',
          },
          {
            id: 'EHK_Betondachsteine',
            name: 'EHK Betondachsteine',
          },
          {
            id: 'ESK_Betondachsteine',
            name: 'ESK Betondachsteine',
          },
        ],
      },
      special: {
        title: 'Sonderklammern',
        text: 'Ausgenommen hiervon sind bestimmte Modelle, bei denen individuelle Sturmklammern angeboten werden. Bitte wählen Sie bei diesen Modellen ebenso die gewünschte standardmäßige Klammervariante (Einhänge- oder Einschlagklammer) und den Lattungsquerschnitt aus. Die individuelle Sturmklammer berücksichtigen wir bei der Berechnung aufgrund Ihrer getätigten Modellauswahl.',
        items: [
          {
            id: 'EHK_Biberschwanzziegel',
            name: 'EHK Biberschwanzziegel',
            link: 'https://www.creaton.de/fileadmin/user_upload/09_DOWNLOAD/Fachleute/Montageanleitungen/Sturmklammern/Montage_Biberklammer.pdf',
          },
          {
            id: 'EHK_SINFONIE',
            name: 'EHK SINFONIE',
          },
          {
            id: 'ESK_ANTICO',
            name: 'ESK ANTICO',
            link: 'https://www.creaton.de/fileadmin/user_upload/09_DOWNLOAD/Fachleute/Montageanleitungen/Sturmklammern/Montage_Sonderklammer_ANTICO.pdf',
          },
          {
            id: 'ESK_AUREUS',
            name: 'ESK AUREUS',
            link: 'https://www.creaton.de/fileadmin/user_upload/09_DOWNLOAD/Fachleute/Montageanleitungen/Sturmklammern/Montage_Sonderklammer_AUREUS.pdf',
          },
          {
            id: 'ESK_ETRUSKO',
            name: 'ESK ETRUSKO',
            link: 'https://www.creaton.de/fileadmin/user_upload/09_DOWNLOAD/Produkte/ETRUSKO/Montage_ETRUSKO.pdf',
          },
          {
            id: 'ESK_KAPSTADT',
            name: 'ESK KAPSTADT',
          },
          {
            id: 'ESK_PROFIL_Strangfalzziegel',
            name: 'ESK PROFIL Strangfalzziegel',
            link: 'https://www.creaton.de/fileadmin/user_upload/09_DOWNLOAD/Fachleute/Montageanleitungen/Sturmklammern/Montage_Sturmklammer_Strangfalz.pdf',
          },
          {
            id: 'ESK_ROMANO',
            name: 'ESK ROMANO',
            link: 'https://www.creaton.de/fileadmin/user_upload/09_DOWNLOAD/Fachleute/Montageanleitungen/Sturmklammern/Montage_Sonderklammer_ROMANO.pdf',
          },
          {
            id: 'SCHRAUBE_Edelstahl',
            name: 'SCHRAUBE Edelstahl',
          },
        ],
      },
    },
  },
  de: {
    modals: {
      windZone: {
        headline: 'Windsogsicherung',
        title: 'Windzonen',
        text: 'Für die Berechnung wird die Windzone, in der das Gebäude erbaut wird, benötigt. Diese wird anhand Ihrer Postleitzahl ermittelt.',
        image: require('../../../../../lib/common/assets/images/windzonen.jpg'),
      },
    },
  },
  at: {},
}
