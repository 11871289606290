/** @jsxImportSource @emotion/react */
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
} from '@mui/material'
import { Section } from '../../../../components'
import { content } from './windsuction.content'
import Clip from './clip'

export default function ClipKindsModals({ isOpen, onClose, ...props }) {
  const modal = content.modals.clipKinds

  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpen} onClose={onClose} {...props}>
      <DialogTitle>{modal.headline}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Section headline={modal.standard.title} subline={modal.standard.text}>
            <Grid container spacing={{ xs: 2, sm: 4 }}>
              {modal.standard.items.map((item, index) => (
                <Grid key={`standard-clip-${index}`} item xs={12} sm={6}>
                  <Clip clip={item} />
                </Grid>
              ))}
            </Grid>
          </Section>
          <Section spacing="topLarge" headline={modal.special.title} subline={modal.special.text}>
            <Grid container spacing={{ xs: 2, sm: 4 }}>
              {modal.special.items.map((item, index) => (
                <Grid key={`special-clip-${index}`} item xs={12} sm={6}>
                  <Clip clip={item} />
                </Grid>
              ))}
            </Grid>
          </Section>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{content.ui.close}</Button>
      </DialogActions>
    </Dialog>
  )
}
