/** @jsxImportSource @emotion/react */
import { Button, Card as MuiCard, CardContent, Grid, Typography } from '@mui/material'
import { locales } from '../../../../../lib/common/enums/locales'
import { content } from './products.content'
import { styles } from './products.styles'

export default function Card({ tile, locale, ...props }) {
  const href = locale === locales.germany ? tile.detailsUrlDE : tile.detailsUrlAT
  return (
    <MuiCard fullWidth {...props} elevation={3}>
      <CardContent>
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid item xs={12} sm={4}>
            <img css={styles.cardImage} src={tile?.image} alt={tile.name} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <div css={styles.card}>
              <Typography variant="h1" component="h3">
                {tile.name}
              </Typography>
              <Button
                css={styles.cardButton}
                title={content.ui.details.meta}
                href={href}
                target="_blank"
                rel="noopener"
                variant="contained"
                disableElevation
              >
                {content.ui.details.text}
              </Button>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </MuiCard>
  )
}
