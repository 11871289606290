export const content = {
  headline: ['Berechnungs- &', 'Planungstool', 'Windsog & Schneelast'],
  info: 'Erstellen Sie schnell und einfach fachgerechte Berechnungen rund um Ihr Dach — Daten nur einmal eingeben und alle Ergebnisse erhalten!',
  action: 'Berechnung starten',
  logo: 'CREATON Logo',
  checkbox: {
    text: ['Ich bin mit den', 'einverstanden'],
    link: 'Berechnungs­hinweisen',
  },
  checkbox_agb: {
    text: ['Hiermit erkläre ich, dass ich die', 'zur Kenntnis genommen und akzeptiert habe.'],
    link: 'Datenschutzerklärung'
  }
}
