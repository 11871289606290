export const content = {
  headline: 'Ihr Ergebnis als PDF',
  subline: {
    text: [
      'Informationen zum Datenschutz entnehmen Sie bitte unserer',
      'Enthalten im PDF sind zusätzlich:',
    ],
    link: {
      text: 'Datenschutzerklärung',
      href: 'https://www.creaton.de/datenschutzerklaerung',
      meta: 'Datenschutzerklärung',
    },
    items: [
      'Verlegeschema für Schneenasen',
      'Verlegeschema für Schneefangsysteme',
      'Schätzung der Menge der Dachziegel/ -steine',
      'Exakte Klammerungsverhältnisse von Sturmklammern zu allen Dachbereichen',
      'Genaue Angaben zu allen Sturmklammern je Dachbereich',
      'Zugrundeliegende Flächenangaben für jeden Dachbereich',
      'Angaben zur Konstruktion des Unterdaches',
    ],
  },
  legalHints: {
    headline: "Rechtliche Hinweise",
    subline: "Wählen Sie diese Option ab um auf die fachlichen und rechtlichen Hinweise im PDF zu verzichten."
  }
}
