import { TextField } from '@mui/material'
import { getIn } from 'formik'

/**
 * Helper Component to bind a material ui textfield with formik
 */
export default function FormikTextField({ formik, name, options, helperText, ...props }) {
  return (
    <TextField
      {...props}
      fullWidth
      variant="standard"
      name={name}
      value={getIn(formik.values, name)}
      onChange={formik.handleChange}
      error={getIn(formik.touched, name) && Boolean(getIn(formik.errors, name))}
      helperText={(getIn(formik.touched, name) && getIn(formik.errors, name)) || helperText || ''}
    />
  )
}
