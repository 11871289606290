/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { isNil } from 'lodash'
import Step1 from '../../containers/tool-page/step1/step1'
import Step2 from '../../containers/tool-page/step2/step2'
import Step3 from '../../containers/tool-page/step3/step3'
import Step4 from '../../containers/tool-page/step4/step4'
import Step5 from '../../containers/tool-page/step5/step5'
import { Page, Header, Cloud } from '../../components'
import { styles } from './tool-page.styles'
import { content } from './tool-page.content'

const steps = [
  { title: content.steps[0], component: Step1 },
  { title: content.steps[1], component: Step2 },
  { title: content.steps[2], component: Step3 },
  { title: content.steps[3], component: Step4 },
  { title: content.steps[4], component: Step5 },
]
const FIRST_STEP = 0
const LAST_STEP = steps.length - 1

export default function ToolPage() {
  const [currentStep, setCurrentStep] = useState(FIRST_STEP)

  const handleBack = (_index) => {
    const index = isNil(_index) ? currentStep - 1 : _index
    const nextStep = 0 <= index && index < currentStep ? index : currentStep
    setCurrentStep(nextStep)
  }

  const handleStart = () => {
    setCurrentStep(0);
  }

  const handleNext = () => {
    const nextStep = currentStep < LAST_STEP ? currentStep + 1 : currentStep
    setCurrentStep(nextStep)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStep])

  return (
    <>
      <Header />
      <Page css={styles.page}>
        <SwipeableViews index={currentStep} disabled={true}>
          {steps.map((step, index) => {
            const Step = step.component
            return (
              <div
                key={`step-${index}`}
                hidden={index !== currentStep}
                aria-labelledby={`step-${index}`}
              >
                <Cloud css={styles.cloud} text={step.title} />
                <Step css={styles.step} nextStep={handleNext} goBack={handleBack} goToStart={handleStart} />
              </div>
            )
          })}
        </SwipeableViews>
      </Page>
    </>
  )
}
