import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from '@mui/material'
import { Section } from '../../../../components'
import { content } from './specifications.content'

export default function UnderRoofModal({ locale, isOpen, onClose, ...props }) {
  const modal = content[locale].modal
  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpen} onClose={onClose} {...props}>
      <DialogTitle>{modal.headline}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Section headline={modal.structures.title}>
            <Typography>{modal.structures.text}</Typography>
          </Section>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{content.ui.close}</Button>
      </DialogActions>
    </Dialog>
  )
}
