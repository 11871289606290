/**
 * This file contains API-related functions.
 * Each of these functions calls an endpoint of the "cretls-backend" API.
 *
 * The API's documentation: https://creaton.de/api/documentation
 */

import { get, post } from './requests'
import { parseBody } from '../utils/parseBody'

const getTiles = async (tileKind) => {
  return await get(`/tiles?tileKind=${tileKind}`)
}

const getSnowZone = async ({ locale, coordinates }) => {
  return await get(
    `/${locale}/snow/snowzone?latitude=${coordinates.lat}&longitude=${coordinates.lng}`
  )
}

const getWindZone = async ({ locale, coordinates }) => {
  return await get(
    `/${locale}/wind/windzone?latitude=${coordinates.lat}&longitude=${coordinates.lng}`
  )
}

const postRoofValidate = async (values) => {
  const body = parseBody(values)
  return await post(`/roof/validate`, body)
}

const postUnderRoofCalculation = async (values) => {
  const { locale, ...body } = parseBody(values)
  return await post(`/${locale}/underRoof/calculate`, body)
}

const postSnowLoadProtectionCalculation = async (values) => {
  const { locale, ...body } = parseBody(values)
  return await post(`/${locale}/snow/load-protection/calculate`, body)
}

const postWindSuctionCalculation = async (values) => {
  const { locale, ...body } = parseBody(values)
  return await post(`/${locale}/wind/windsuction/calculate`, body)
}

const postPDF = async (values) => {
  const { locale, ...body } = parseBody(values)
  return await post(`/${locale}/pdf`, body, 'blob')
}

export const api = {
  getTiles,
  getSnowZone,
  getWindZone,
  postRoofValidate,
  postUnderRoofCalculation,
  postSnowLoadProtectionCalculation,
  postWindSuctionCalculation,
  postPDF,
}

export default api
