export const content = {
  ui: {
    close: 'Schließen',
  },
  de: {
    headline: 'Dachspezifikationen',
    subline: {
      text: [
        'Unser Tool unterstützt Sie bei der Berechnung verschiedener Dachformen. Hierzu wird ebenfalls eine Information zu Ihrem',
        'benötigt.',
      ],
      link: 'Dachaufbau',
    },
    modal: {
      headline: 'Dachspezifikationen',
      structures: {
        title: 'Dachaufbau',
        text: 'Wählen Sie zwischen einer offenen Deckunterlage wie z.B. einer Unterspannung überlappt oder einer geschlossenen Deckunterlage wie z.B. einer Unterspannung verklebt, einer Unterdeckplatte, Unterdeckbahn, Schalung, Zwischen- oder Aufsparrendämmung.',
      },
    },
  },
  at: {
    headline: 'Dachspezifikationen',
    subline: {
      text: [
        'Unser Tool unterstützt Sie bei der Berechnung verschiedener Dachformen. Hierzu wird ebenfalls eine Angabe zur',
        'benötigt.',
      ],
      link: 'Deckung mit Unterdach',
    },
    modal: {
      headline: 'Dachspezifikationen',
      structures: {
        title: 'Deckung mit Unterdach',
        text: 'In der ÖNORM B 3419 ist eine Berechnung ohne Unterdach möglich.',
      },
    },
  },
}
