/** @jsxImportSource @emotion/react */
import { TextField, MenuItem } from '@mui/material'
import { getIn } from 'formik'

/**
 * Helper Component to bind a material ui select with formik
 */
export default function FormikSelect({ formik, name, options, ...props }) {
  return (
    <TextField
      {...props}
      select
      fullWidth
      variant="standard"
      name={name}
      value={getIn(formik.values, name)}
      onChange={formik.handleChange}
      error={getIn(formik.touched, name) && Boolean(getIn(formik.errors, name))}
      helperText={(getIn(formik.touched, name) && getIn(formik.errors, name)) || ''}
    >
      <MenuItem value={null} aria-hidden="true" css={{ display: 'none', visibility: 'hidden' }} />
      <MenuItem value={''} aria-hidden="true" css={{ display: 'none', visibility: 'hidden' }} />
      {options.map((item, index) => (
        <MenuItem key={`${item.id}-${index}`} value={item?.value ?? item.id}>
          {item.desc}
        </MenuItem>
      ))}
    </TextField>
  )
}
