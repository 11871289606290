/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react'
import { InputAdornment } from '@mui/material'
import { useFormikContext } from 'formik'
import { Section, Link, FormikTextField, Icon } from '../../../../components'
import { FormContext } from '../../../../context/form-context'
import { content } from './dimensions.content'
import config from '../../../../../lib/config/de-at.config.json'
import { regulations } from '../../../../../lib/common/enums/regulations'
import DimensionsModal from './dimensions-modal'
import FormikHelperText from "../../../../components/formik-helpertext/formik-helpertext";
import isLowRoofPitch from "../../../../../lib/utils/is-low-roof-pitch";

const dimensions = config.building.properties.dimensions
const roofKinds = config.building.properties.roofKinds

export default function Dimensions({ onChange, ...props }) {
  const { formState } = useContext(FormContext)
  const [isDimensionsModalOpen, setDimensionsModalOpen] = useState(false)
  const { values, ...formik } = useFormikContext()
  const [showLowRoofPitchWarning, setShowLowRoofPitchWarning] = useState(false);

  useEffect(() => {
    if(isLowRoofPitch(values?.dimensions?.roofPitch)) {
      if(!showLowRoofPitchWarning) {
        setShowLowRoofPitchWarning(true);
      }
    } else if (showLowRoofPitchWarning) {
      setShowLowRoofPitchWarning(false);
    }

    onChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.dimensions])

  const parseLabel = (label, roofKind) => {
    if (Array.isArray(label)) {
      return roofKind === roofKinds.items[3].id ? label[1] : label[0]
    } else {
      return label
    }
  }

  return (
    <div {...props}>
      <Section
        spacing="topLarge"
        headline={content.headline}
        subline={
          <>
            <span>{content.subline.text[0]}</span>{' '}
            <Link onClick={() => setDimensionsModalOpen(true)}>{content.subline.link}</Link>{' '}
            <span>{content.subline.text[1]}</span>
          </>
        }
      >
        {Object.values(dimensions).map((dimension) => {
          // hip-roof-pitch only required for hip
          if (
            values?.roofKind !== roofKinds.items[2].id &&
            dimension.id === dimensions.hipPitch.id
          ) {
            return null
          }

          if (
            formState?.location?.regulation !== regulations.EXTENDED_AT &&
            dimension.id === dimensions.distanceUpperLowerShell.id
          ) {
            return null
          }

          return (
            <div
              key={`${values?.roofKind}-${dimension.id}`}
              css={{ display: 'flex', justifyContent: 'center' }}
            >
              <Icon
                css={{ marginLeft: '-6px' }}
                size={65}
                path={`${values?.roofKind}/${dimension.icon}`}
                alt={`${values?.roofKind}-${dimension.id}`}
              />
              <FormikTextField
                formik={{ values, ...formik }}
                label={parseLabel(dimension.desc, values?.roofKind)}
                name={`dimensions.${dimension.id}`}
                css={{ marginLeft: '20px' }}
                inputProps={{
                  step: 'any',
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  type: 'number',
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{dimension.unit}</InputAdornment>,
                }}
                helperText={(dimension.id === "roofPitch" && showLowRoofPitchWarning) ? <FormikHelperText text={content.roofPitch.lowRoofPitchWarning} isWarning={true} /> : '' }
                required
              />
            </div>
          )
        })}
      </Section>

      {/* MODALS */}
      <DimensionsModal
        isOpen={isDimensionsModalOpen}
        onClose={() => setDimensionsModalOpen(false)}
      />
    </div>
  )
}
