/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material'
import { styles } from './section.styles'

export default function Section({ children, spacing, headline, subline, ...props }) {
  return (
    <div {...props}>
      <div css={spacing ? styles[spacing] : {}}>
        {(headline || subline) && (
          <div css={styles.header}>
            {headline && (
              <Typography variant="h3" component="h2">
                {headline}
              </Typography>
            )}
            {subline && <span>{subline}</span>}
          </div>
        )}
        {children}
      </div>
    </div>
  )
}
