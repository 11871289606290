import backgroundImage from '../../../lib/common/assets/images/background.jpg'

const page = (theme) => ({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2),
  paddingTop: theme.spacing(7),
  backgroundColor: theme.palette.primary.main,
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
})

const logo = (theme) => {
  return {
    position: 'absolute',
    width: theme.spacing(25),
    top: theme.spacing(3),
    right: theme.spacing(3),
  }
}

const cloud = (theme) => ({
  marginBottom: `-${theme.spacing(0.5)}`,
  transform: 'scale(1.25)',
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    marginBottom: `${theme.spacing(0.5)}`,
    marginLeft: theme.spacing(5),
    transform: 'scale(1.5)',
  },
})

const card = (theme) => ({
  width: '100%',
  maxWidth: '400px',
  padding: theme.spacing(3),
  paddingTop: theme.spacing(6),
  background: theme.palette.common.white,
})

const button = (theme) => ({
  marginTop: theme.spacing(3),
})

export const styles = {
  page,
  cloud,
  card,
  button,
  logo,
}
