const error = {
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
}

const details = (theme) => ({
  marginBottom: theme.spacing(1),
})

export const styles = {
  error,
  details,
}
