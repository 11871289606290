/** @jsxImportSource @emotion/react */
import { useState, useContext } from 'react'
import { useFormikContext } from 'formik'
import { Grid, InputAdornment } from '@mui/material'
import {
  Section,
  Link,
  FormikSelect,
  FormikTextField,
  FormikCheckbox,
} from '../../../../components'
import config from '../../../../../lib/config/de-at.config.json'
import { locales } from '../../../../../lib/common/enums/locales'
import { FormContext } from '../../../../context/form-context'
import { content } from './snowload.content'
import SnowZoneModal from './snowzone-modal'
import SnowEquipmentModal from './snow-equipment-modal'
import SnowProtectionModal from './snow-protection-modal'
import { useEffect } from 'react'

const protectionProps = config.protection.properties
const MAX_PITCH = 35

export default function SnowLoad(props) {
  const { formState } = useContext(FormContext)
  const formik = useFormikContext()
  const [isSnowZoneModalOpen, setSnowZoneModalOpen] = useState(false)
  const [isSnowEquipmentModalOpen, setSnowEquipmentModalOpen] = useState(false)
  const [isSnowProtectionModalOpen, setSnowProtectionModalOpen] = useState(false)
  const [isSnowNoseMandatory, setSnowNoseMandatory] = useState(false)

  /**
   * isSnowNose has to be true in austria if pitch is larger than 35
   */
  useEffect(() => {
    const locale = formState?.place.locale
    const roofPitch = formState?.dimensions?.roofPitch
    const hipPitch = formState?.dimensions?.hipPitch
    const isSnowNoseMandatory =
      locale === locales.austria && (roofPitch >= MAX_PITCH || hipPitch >= MAX_PITCH)
    setSnowNoseMandatory(isSnowNoseMandatory)
    if (isSnowNoseMandatory) {
      formik.setFieldValue('isSnowNose', true, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState?.dimensions, formState?.place])

  /**
   * If preloaded snowZone exists, set it automatically
   */
  useEffect(() => {
    formik.setFieldValue('snowZone', formState?.snowZone)
    formik.setFieldValue('snowGroundLoad', formState?.snowGroundLoad)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState?.snowZone])

  return (
    <div {...props}>
      <Section
        spacing="topLarge"
        headline={content.headline}
        subline={
          <>
            <span>{content.subline.text[0]}</span>{' '}
            <Link onClick={() => setSnowZoneModalOpen(true)}>{content.subline.link[0]}</Link>{' '}
            <span>{content.subline.text[1]}</span>{' '}
            <Link onClick={() => setSnowEquipmentModalOpen(true)}>{content.subline.link[1]}</Link>
            {', '}
            <Link onClick={() => setSnowProtectionModalOpen(true)}>
              {content.subline.link[2]}
            </Link>{' '}
            <span>{content.subline.text[2]}</span>
          </>
        }
      >
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid item xs={12} sm={6}>
            <FormikTextField
              disabled
              formik={formik}
              label={protectionProps.snowZones.desc}
              name={protectionProps.snowZones.id}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikTextField
              disabled
              formik={formik}
              label={protectionProps.snowGroundLoad.desc}
              name={protectionProps.snowGroundLoad.id}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {protectionProps.snowGroundLoad.unit}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikSelect
              formik={formik}
              name={protectionProps.snowCatcherEquipmentKind.id}
              label={protectionProps.snowCatcherEquipmentKind.desc}
              options={protectionProps.snowCatcherEquipmentKind.items}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikSelect
              formik={formik}
              name={protectionProps.snowProtectionKind.id}
              label={protectionProps.snowProtectionKind.desc}
              options={protectionProps.snowProtectionKind.items}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormikCheckbox
              disabled={isSnowNoseMandatory}
              formik={formik}
              name={protectionProps.isSnowNose.id}
              label={protectionProps.isSnowNose.desc}
            />
          </Grid>
        </Grid>
      </Section>

      {/* MODALS */}
      <SnowZoneModal
        locale={formState.place.locale}
        isOpen={isSnowZoneModalOpen}
        onClose={() => setSnowZoneModalOpen(false)}
      />
      <SnowEquipmentModal
        locale={formState.place.locale}
        isOpen={isSnowEquipmentModalOpen}
        onClose={() => setSnowEquipmentModalOpen(false)}
      />
      <SnowProtectionModal
        isOpen={isSnowProtectionModalOpen}
        onClose={() => setSnowProtectionModalOpen(false)}
      />
    </div>
  )
}
