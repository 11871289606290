/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { Alert as MuiAlert, AlertTitle, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styles } from './alert.styles'
import { content } from './alert.content'

export default function Alert({ title, children, error, ...props }) {
  const [isExtended, setExtended] = useState(false)

  const handleExpand = () => {
    setExtended((state) => !state)
  }

  return (
    <MuiAlert variant="filled" {...props}>
      <AlertTitle>{title}</AlertTitle>
      {children && <div css={error && styles.details}>{children}</div>}
      {error && (
        <>
          <div onClick={handleExpand} css={styles.error}>
            <Typography variant="body2">{content.error}</Typography>
            <ExpandMoreIcon />
          </div>
          {isExtended && <Typography variant="body2">{error}</Typography>}
        </>
      )}
    </MuiAlert>
  )
}
