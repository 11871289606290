/**
 * Get data from an API endpoint
 *
 * @async
 * @param {string} path API path
 * @param {string} type Content-Type
 * @returns {Promise<object>} A promise that resolves with a json data object
 */
export const get = async (path, type = 'json') => {
  const url = `${process.env.REACT_APP_API_ENDPOINT_URL}${path}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': `application/${type}`,
      'x-api-key': process.env.REACT_APP_API_KEY,
    },
  })
  if (response.ok) {
    return type === 'blob' ? response.blob() : response.json()
  } else {
    const error = await response.json()
    throw new Error(JSON.stringify(error))
  }
}

/**
 * Send data to an API endpoint using the POST method
 *
 * @async
 * @param {string} path API path
 * @param {object} body JSON object containing the data
 * @param {string} type Content-Type
 * @returns {Promise<object>} A promise that resolves with a JSON object
 */
export const post = async (path, body, type = 'json') => {
  const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT_URL}${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': `application/json`,
      'x-api-key': process.env.REACT_APP_API_KEY,
    },
    body: JSON.stringify(body),
  })
  if (response.ok) {
    return type === 'blob' ? response.blob() : response.json()
  } else {
    const error = await response.json()
    throw new Error(JSON.stringify(error))
  }
}
