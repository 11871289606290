import { useContext, useEffect, useState } from 'react'
import GoogleService from '../../lib/serivces/google.service'
import { GoogleApiContext } from '../context/google-api-context'

/**
 * Hook providing functions for google api
 */
const useGoogle = () => {
  const { google } = useContext(GoogleApiContext)
  const [service, setService] = useState()

  useEffect(() => {
    if (google && !service) {
      const service = new GoogleService(google)
      setService(service)
    }
  }, [google, service])

  return {
    google,
    googleService: service,
  }
}

export default useGoogle
