const map = (theme) => ({
  display: 'block',
  height: theme.spacing(50),
  width: 'auto',
  margin: '0 auto',
})

const clipImage = (theme) => ({
  display: 'block',
  height: theme.spacing(14),
  width: 'auto',
  margin: '0 auto',
})

const clipText = (theme) => ({
  display: 'block',
  textAlign: 'center',
  margin: '0 auto',
})

export const styles = {
  map,
  clipImage,
  clipText,
}
