/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react'
import { Typography, Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import configAT from '../../../../../lib/config/at.config.json'
import config from '../../../../../lib/config/de-at.config.json'
import BuildingLocationModal from './building-location-modal'
import { locales } from '../../../../../lib/common/enums/locales'
import { regulations } from '../../../../../lib/common/enums/regulations'
import { buildingLocations } from '../../../../../lib/common/enums/building-locations'
import { Section, FormikSelect, FormikCheckbox, Link } from '../../../../components'
import { content } from './location.content'
import RegulationsModal from './regulations-modal'
import TerrainsModal from './terrains-modal'

const altitudeProps = config.location.properties.altitude
const regulationProps = configAT.location.properties.regulations
const terrainProps = configAT.location.properties.terrainCategories
const locationProps = configAT.location.properties.locations[0]

export default function LocationAT(props) {
  const { values, ...formik } = useFormikContext()
  const [isLocationModalOpen, setLocationModalOpen] = useState(false)
  const [isRegulationsModalOpen, setRegulationsModalOpen] = useState(false)
  const [isTerrainsModalOpen, setTerrainsModalOpen] = useState(false)
  const locale = locales.austria

  /**
   * Set buildingLocation
   * AT has only "inland" as a possible location
   */
  useEffect(() => {
    formik.setFieldValue('location.buildingLocation', buildingLocations.inland, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div {...props}>
      {/* LOCATION */}
      <Section
        spacing="topLarge"
        headline={content[locale].location.headline}
        subline={
          <>
            <span>{content[locale].location.subline.text[0]}</span>{' '}
            <Link onClick={() => setLocationModalOpen(true)}>
              {content[locale].location.subline.link}
            </Link>{' '}
            <span>{content[locale].location.subline.text[1]}</span>
          </>
        }
      >
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid item xs={12} sm={6}>
            <FormikCheckbox
              formik={{ values, ...formik }}
              name={`location.${locationProps.id}`}
              label={locationProps.desc}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption" color="info">
              {altitudeProps.desc}
            </Typography>
            <Typography variant="body1">
              {values.place.altitude} {altitudeProps.unit}
            </Typography>
          </Grid>
        </Grid>
      </Section>

      {/* REGULATIONS */}
      <Section
        spacing="topMedium"
        headline={content[locale].regulations.headline}
        subline={
          <>
            <span>{content[locale].regulations.subline.text[0]}</span>{' '}
            <Link onClick={() => setRegulationsModalOpen(true)}>
              {content[locale].regulations.subline.link}
            </Link>{' '}
            <span>{content[locale].regulations.subline.text[1]}</span>
          </>
        }
      >
        <Grid container spacing={{ xs: 0, sm: 4 }}>
          <Grid item xs={12} sm={6}>
            <FormikSelect
              formik={{ values, ...formik }}
              name={`location.${regulationProps.id}`}
              label={regulationProps.desc}
              options={regulationProps.items}
              required
            />
          </Grid>
        </Grid>
      </Section>

      {/* TERRAINS */}
      {values.location.regulation === regulations.EXTENDED_AT && (
        <Section
          spacing="topLarge"
          headline={content[locale].terrains.headline}
          subline={
            <>
              <span>{content[locale].terrains.subline.text[0]}</span>{' '}
              <Link onClick={() => setTerrainsModalOpen(true)}>
                {content[locale].terrains.subline.link}
              </Link>{' '}
              <span>{content[locale].terrains.subline.text[1]}</span>
            </>
          }
        >
          <Grid container spacing={{ xs: 0, sm: 4 }}>
            <Grid item xs={12} sm={6}>
              <FormikSelect
                formik={{ values, ...formik }}
                name={`location.${terrainProps.id}`}
                label={terrainProps.desc}
                options={terrainProps.items}
                required
              />
            </Grid>
          </Grid>
        </Section>
      )}

      {/* MODALS*/}
      <BuildingLocationModal
        locale={locale}
        isOpen={isLocationModalOpen}
        onClose={() => setLocationModalOpen(false)}
      />
      <RegulationsModal
        locale={locale}
        isOpen={isRegulationsModalOpen}
        onClose={() => setRegulationsModalOpen(false)}
      />
      <TerrainsModal
        locale={locale}
        isOpen={isTerrainsModalOpen}
        onClose={() => setTerrainsModalOpen(false)}
      />
    </div>
  )
}
