/** @jsxImportSource @emotion/react */
import { Button, CircularProgress } from '@mui/material'
import { content } from './formik-navigation.content'
import { styles } from './formik-navigation.styles'

export default function FormikNavigation({ onBack, submitLabel, disabled, loading, ...props }) {
  return (
    <div {...props} css={styles.wrapper}>
      {onBack && (
        <Button type="button" onClick={onBack}>
          {content.ui.prev}
        </Button>
      )}
      <Button
        css={styles.submit}
        startIcon={loading && <CircularProgress color="inherit" size={18} thickness={6} />}
        type="submit"
        variant="contained"
        disabled={disabled || loading}
        disableElevation
      >
        {submitLabel ?? content.ui.next}
      </Button>
    </div>
  )
}
