import { find, includes, get } from 'lodash'

/**
 * This file contains google api helper functions.
 * It depends on a google instance, loaded by `config/google-api-loader.js`.
 */
export default class GoogleService {
  constructor(google) {
    this.google = google
    this.Elevator = new google.maps.ElevationService()
    this.Geocoder = new google.maps.Geocoder()
    this.Autocompleter = new google.maps.places.AutocompleteService()
  }

  async getSuggestions(input) {
    return new Promise((resolve) => {
      const componentRestrictions = { country: ['de', 'at'] }
      this.Autocompleter.getPlacePredictions({ input, componentRestrictions }, resolve)
    })
  }

  async getPlace(placeId) {
    if (!placeId) {
      throw new Error('[GoogleService.getPlace]: placeId is required!')
    }
    return new Promise(async (resolve, reject) => {
      this.Geocoder.geocode({ placeId }, async (results, status) => {
        if (status !== 'OK') {
          return reject(new Error('[GoogleService.getPlace]: geocode not found!'))
        }
        const geocode = results[0]
        const lat = get(geocode, 'geometry.location.lat')()
        const lng = get(geocode, 'geometry.location.lng')()
        const formatted = get(geocode, 'formatted_address')
        const bounds = get(geocode, 'geometry.viewport')
        const locale = this._getValueByAddressComponents(
          geocode?.address_components,
          'country',
          'short_name'
        ).toLowerCase()
        const altitude = await this._getAltitude(lat, lng)
        let zip = this._getValueByAddressComponents(geocode?.address_components, 'postal_code')
        if (!zip) {
          zip = await this._getPostalCode(lat, lng)
        }
        return resolve({
          zip,
          locale,
          formatted,
          bounds,
          altitude,
          coordinates: { lat, lng },
        })
      })
    })
  }

  async _getAltitude(lat, lng) {
    return new Promise((resolve) => {
      const location = new this.google.maps.LatLng(lat, lng)
      this.Elevator.getElevationForLocations({ locations: [location] }, (res) => {
        const elevation = parseInt(res[0]?.elevation)
        return resolve(elevation)
      })
    })
  }

  async _getPostalCode(lat, lng) {
    return new Promise((resolve) => {
      this.Geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status !== 'OK') return resolve(null)
        const result = results[0]
        const zip = this._getValueByAddressComponents(result.address_components, 'postal_code')
        return resolve(zip)
      })
    })
  }

  _getValueByAddressComponents(addressComponents, type, value = 'long_name') {
    const component = find(addressComponents, (item) => includes(item.types, type))
    return get(component, value)
  }
}
