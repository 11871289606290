import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from '@mui/material'
import { Section } from '../../../../components'
import { content } from './location.content'

export default function RegulationsModal({ locale, isOpen, onClose, ...props }) {
  const modal = content[locale].regulations.modal
  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpen} onClose={onClose} {...props}>
      <DialogTitle>{modal.headline}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {modal.items.map((item, index) => (
            <Section
              key={`${item.title}-${index}`}
              spacing={index > 0 && 'topMedium'}
              headline={item.title}
            >
              <Typography>{item.text}</Typography>
            </Section>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{content.ui.close}</Button>
      </DialogActions>
    </Dialog>
  )
}
