/** @jsxImportSource @emotion/react */
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from '@mui/material'
import { Section } from '../../../../components'
import { content } from './snowload.content'
import { styles } from './snowload.styles'

export default function SnowEquipmentModal({ locale, isOpen, onClose, ...props }) {
  const modal = content[locale].modals.snowCatcherEquipment

  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpen} onClose={onClose} {...props}>
      <DialogTitle>{modal.headline}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Section headline={modal.systems.title}>
            <Typography>{modal.systems.text}</Typography>
            {modal.systems.items.map((item, index) => (
              <div key={`${item.name}-${index}`} css={styles.equipmentImage}>
                <img src={item.image} alt={item.name} />
                <Typography>{item.name}</Typography>
              </div>
            ))}
          </Section>
          <Section spacing="topLarge" headline={modal.snownose.title}>
            <Typography>{modal.snownose.text}</Typography>
            <div css={styles.equipmentImage}>
              <img src={modal.snownose.image} alt={modal.snownose.name} />
              <Typography>{modal.snownose.title}</Typography>
            </div>
          </Section>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{content.ui.close}</Button>
      </DialogActions>
    </Dialog>
  )
}
