const frame = (theme) => ({
  position: 'relative',
  color: theme.palette.primary.main,
  overflow: 'hidden',
  height: theme.spacing(18),
})

const background = {
  width: '100%',
}

const logo = (theme) => {
  return {
    position: 'absolute',
    top: '15px',
    right: '15px',
    width: theme.spacing(15),
    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
      width: theme.spacing(20),
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      width: theme.spacing(25),
      top: theme.spacing(3),
      right: theme.spacing(3),
    },
  }
}

export const styles = {
  frame,
  background,
  logo,
}
