/** @jsxImportSource @emotion/react */
import { Grid } from '@mui/material'
import { useState, useEffect, useContext } from 'react'
import { FormikSelect, FormikTextField, Section, Link } from '../../../../components'
import config from '../../../../../lib/config/de-at.config.json'
import { FormContext } from '../../../../context/form-context'
import { content } from './windsuction.content'
import { useFormikContext } from 'formik'
import WindZoneModal from './windzone-modal'
import ClipKindsModals from './clip-kinds-modal'

const protectionProps = config.protection.properties

export default function WindSuctionDE(props) {
  const { formState } = useContext(FormContext)
  const [isClipKindModalOpen, setClipKindModalOpen] = useState(false)
  const [isWindZoneModalOpen, setWindZoneModalOpen] = useState(false)
  const formik = useFormikContext()

  useEffect(() => {
    formik.setFieldValue('windZone', formState?.windZone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState?.windZone])

  return (
    <div {...props}>
      <Section
        headline={content.headline}
        subline={
          <>
            <span>{content.subline.text[0]}</span>{' '}
            <Link onClick={() => setWindZoneModalOpen(true)}>{content.subline.link[0]}</Link>{' '}
            <span>{content.subline.text[1]}</span>{' '}
            <Link onClick={() => setClipKindModalOpen(true)}>{content.subline.link[1]}</Link>{' '}
            <span>{content.subline.text[2]}</span>
          </>
        }
      >
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid item xs={12} sm={6}>
            <FormikTextField
              disabled
              formik={formik}
              name={protectionProps.windZones.id}
              label={protectionProps.windZones.desc}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikSelect
              formik={formik}
              name={protectionProps.clip.id}
              label={protectionProps.clip.desc}
              options={
                formState?.tile?.clips.map((item) => ({
                  id: item.id,
                  desc: item.title,
                  value: item,
                })) ?? []
              }
              required
            />
          </Grid>
        </Grid>
      </Section>

      {/* MODALS */}
      <WindZoneModal isOpen={isWindZoneModalOpen} onClose={() => setWindZoneModalOpen(false)} />
      <ClipKindsModals isOpen={isClipKindModalOpen} onClose={() => setClipKindModalOpen(false)} />
    </div>
  )
}
