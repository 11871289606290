/** @jsxImportSource @emotion/react */
import { useState, useContext, Fragment } from 'react'
import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import { Section, FormikSelect, FormikCheckbox, Link, Icon } from '../../../../components'
import { content } from './specifications.content'
import config from '../../../../../lib/config/de-at.config.json'
import configAT from '../../../../../lib/config/at.config.json'
import { locales } from '../../../../../lib/common/enums/locales'
import UnderRoofModal from './under-roof-modal'
import { regulations } from '../../../../../lib/common/enums/regulations'
import { FormContext } from '../../../../context/form-context'
import { styles } from './specifications.styles'

const roofKindProps = config.building.properties.roofKinds
const distanceUpperLowerShellProps = configAT.building.properties.distanceUpperLowerShell

export default function SpecificationAT(props) {
  const { formState } = useContext(FormContext)
  const [isUnderRoofModalOpen, setUnderRoofModalOpen] = useState(false)
  const formik = useFormikContext()
  const locale = locales.austria

  /**
   * always use under-roof for extended regulation
   */
  const isUnderRoofRequired = () => {
    return formState?.location?.regulation === regulations.EXTENDED_AT
  }

  return (
    <div {...props}>
      <Section
        spacing="topLarge"
        headline={content[locale].headline}
        subline={
          <>
            <span>{content[locale].subline.text[0]}</span>{' '}
            <Link onClick={() => setUnderRoofModalOpen(true)}>{content[locale].subline.link}</Link>{' '}
            <span>{content[locale].subline.text[1]}</span>
          </>
        }
      >
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid item xs={12} sm={6}>
            <FormikCheckbox
              disabled={isUnderRoofRequired()}
              formik={formik}
              name={distanceUpperLowerShellProps.id}
              label={distanceUpperLowerShellProps.desc}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikSelect
              formik={formik}
              name={roofKindProps.id}
              label={roofKindProps.desc}
              options={roofKindProps.items.map((type) => ({
                id: type.id,
                desc: (
                  <Fragment key={type.id}>
                    <Icon
                      size={32}
                      css={styles.image}
                      alt={type.id}
                      path={`${type.id}/${type.icon}`}
                    />
                    <span css={styles.text}>{type.desc}</span>
                  </Fragment>
                ),
              }))}
              required
            />
          </Grid>
        </Grid>
      </Section>

      {/* MODALS */}
      <UnderRoofModal
        locale={locale}
        isOpen={isUnderRoofModalOpen}
        onClose={() => setUnderRoofModalOpen(false)}
      />
    </div>
  )
}
