/** @jsxImportSource @emotion/react */
import { Button, Link } from '@mui/material'
import { Section, Cloud } from '../../../../components'
import config from '../../../../../lib/config/de-at.config.json'
import { content } from './success.content'
import { styles } from './success.styles'

const websiteProps = config.result.properties.website

export default function Success({ onBack, onCustomize, ...props }) {
  return (
    <div {...props}>
      <Section spacing="verticalLarge">
        <Cloud text={content.cloud} />
        <div css={styles.wrapper}>
	  <Button type="button" variant="contained" disableElevation onClick={onCustomize}>
	    {content.customize}
	  </Button>
	  <p>
            <Button type="button" variant="outlined" disableElevation onClick={onBack}>
              {content.back}
            </Button>
	  </p>
          <Link
            css={styles.link}
            href={websiteProps.link}
            title={websiteProps.desc}
            target="_blank"
            rel="noopener"
          >
            {content.link}
          </Link>
        </div>
      </Section>
    </div>
  )
}
