/** @jsxImportSource @emotion/react */
import { useContext } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import Place from './place/place'
import LocationDE from './location/location-de'
import LocationAT from './location/location-at'
import { FormContext } from '../../../context/form-context'
import { CacheContext } from '../../../context/cache-context'
import { locales } from '../../../../lib/common/enums/locales'
import { buildingLocations } from '../../../../lib/common/enums/building-locations'
import { Contact, FormikNavigation, Section, Alert } from '../../../components'
import { regulations } from '../../../../lib/common/enums/regulations'
import { content } from './step1.content'
import { useZonesApi } from '../../../hooks/use-api'
import {mathRoundPrecision} from "../../../../lib/utils/math-round-precision";

const validationSchema = yup.object().shape({
  place: yup.object().shape({
    locale: yup.string().required(content.errors.required),
  }),
  location: yup.mixed().when(['place.locale'], {
    is: locales.germany,
    // DE
    then: yup.object().shape({
      buildingLocation: yup.string().required(content.errors.required),
    }),
    // AT
    otherwise: yup.object().shape({
      regulation: yup.string().required(content.errors.required),
      terrainCategory: yup.mixed().when('regulation', {
        is: regulations.EXTENDED_AT,
        then: yup.string().required(content.errors.required),
      }),
    }),
  }),
})

const initialValues = {
  place: {
    locale: '',
  },
  location: {
    buildingLocation: '',
    exposedLocation: false,
    northGermanPlain: false,
    regulation: '',
    terrainCategory: '',
  },
}

const hasFullPlace = (formState) => {
  const place = formState?.place
  return (
    Boolean(place?.locale) && !isNaN(place?.coordinates?.lat) && !isNaN(place?.coordinates?.lng)
  )
}

export default function Step1({ nextStep, goBack, ...params }) {
  const { formState, setFormState } = useContext(FormContext)
  const { setCache } = useContext(CacheContext)
  const {
    isLoading,
    isError,
    errorMessage,
    reset: resetZonesApi,
  } = useZonesApi({
    params: formState.place,
    enabled: hasFullPlace(formState),
    onSuccess: ({ windZoneDlubal, snowZoneDlubal }) => {
      let snowGroundLoad = '';

      if(snowZoneDlubal?.snowGroundLoad) {
        snowGroundLoad = mathRoundPrecision(snowZoneDlubal.snowGroundLoad, 2);
      }

      setFormState((formState) => ({
        ...formState,
        windZone: windZoneDlubal?.windZone ?? '',
        snowZone: snowZoneDlubal?.snowLoadZone ?? '',
        snowGroundLoad: snowGroundLoad,
      }))
    },
  })

  const handleSubmit = async (values) => {
    const newState = { ...formState, ...values }
    setFormState(newState)
    nextStep()
  }

  const handleNewPlace = (place) => {
    setFormState((formState) => ({ ...formState, place }))
    setCache((cache) => ({ ...cache, windZoneDlubal: undefined, snowZoneDlubal: undefined }))
  }

  /**
   * Cases: No calculation possible
   */
  const isBlocked = (formik) => {
    const isBlockedDE = () => {
      const { inland, seashore, balticSea } = buildingLocations
      const windZone = parseInt(formState?.windZone)
      const buildingLocation = formik?.values?.location?.buildingLocation
      const allowedBuildingLocations = {
        1: [inland],
        2: [inland, seashore, balticSea],
        3: [inland, seashore, balticSea],
        4: [inland, seashore, balticSea],
      }
      return (
        formik?.values?.place?.altitude > 1100 ||
        (Boolean(buildingLocation) &&
          Boolean(windZone) &&
          !allowedBuildingLocations[windZone]?.includes(buildingLocation))
      )
    }
    return (
      !formik?.values?.place?.locale ||
      isError ||
      formik?.values?.location?.exposedLocation ||
      (formik?.values?.place?.locale === locales.germany && isBlockedDE())
    )
  }

  return (
    <div {...params}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Place onSelect={resetZonesApi} onNewPlace={handleNewPlace} disabled={isLoading} />
            {hasFullPlace(formState) && formState.place.locale === locales.germany && (
              <LocationDE />
            )}
            {hasFullPlace(formState) && formState.place.locale === locales.austria && (
              <LocationAT />
            )}

            {/* ALERTS */}
            {isError && (
              <Section spacing="topMedium">
                <Alert severity="error" title={content.errors.zonesApi} error={errorMessage}>
                  <Contact />
                </Alert>
              </Section>
            )}
            {Boolean(formik?.values?.place?.locale) && isBlocked(formik) && (
              <Section spacing="topMedium">
                <Alert severity="warning" title={content.errors.altitude}>
                  <Contact />
                </Alert>
              </Section>
            )}

            <Section spacing="verticalLarge">
              <FormikNavigation disabled={isBlocked(formik) && !isLoading} loading={isLoading} />
            </Section>
          </Form>
        )}
      </Formik>
    </div>
  )
}
