import { pickBy, isNil } from 'lodash'
import configDE from '../config/de.config.json'

/**
 * Helper to parse location data
 * @param {*} data
 * @returns
 */
const _parseLocation = (data) => {
  const location = configDE.location.properties.locations
    .map((item) => data[item.id] && item.id)
    .filter((id) => !!id)
  return { ...data, location }
}

/**
 * Flat and parse values to be usable for fetch-requests
 * @param {*} values
 * @returns
 */
export const parseBody = (values) => {
  const { place, location, dimensions, tile, clip, cache, ...rest } = values
  return pickBy(
    {
      ...rest,
      ..._parseLocation(location),
      ...place,
      ...dimensions,
      tile: tile?.id,
      supportSnowCalculation: tile?.supportSnowCalculation,
      clip: clip?.id,
      baseAltitude: cache?.windZoneDlubal?.altitude,
      baseSpeedPrinting: cache?.windZoneDlubal?.baseSpeedPrinting,
      snowLoadProtection: cache?.snowLoadProtection,
      windSuction: cache?.windSuction,
      underRoofCalculation: cache?.underRoofCalculation,
    },
    (value) => !isNil(value) && !(value === '')
  )
}
