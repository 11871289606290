/** @jsxImportSource @emotion/react */
import { useContext, useCallback, useState, useEffect } from 'react'
import { Grid, Autocomplete, TextField } from '@mui/material'
import { useFormikContext } from 'formik'
import { throttle } from 'lodash'
import { Section } from '../../../../components'
import { FormContext } from '../../../../context/form-context'
import { print } from '../../../../../lib/utils/console'
import useGoogle from '../../../../hooks/use-google'
import Map from './map'
import { content } from './place.content'

export default function Place({ onSelect, onNewPlace, disabled, ...props }) {
  const { googleService } = useGoogle()
  const formik = useFormikContext()
  const { formState, resetFormState } = useContext(FormContext)
  const [suggestions, setSuggestions] = useState([])

  const handleSelect = async (newValue) => {
    onSelect()
    try {
      const place = await googleService.getPlace(newValue?.place_id)
      resetFormState()
      formik.handleReset()
      formik.setFieldValue('place', place, false)
    } catch (error) {
      print(error, 'error')
    }
  }

  /**
   * Trigger to notify if new place is loaded
   */
  useEffect(() => {
    onNewPlace(formik?.values?.place)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.place])

  const handleInputChange = async (newValue) => {
    searchPlaces(newValue)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchPlaces = useCallback(
    throttle(async (newValue) => {
      const newSuggestions = await googleService.getSuggestions(newValue)
      setSuggestions(newSuggestions ?? [])
    }, 300),
    [googleService]
  )

  return (
    <Section {...props} headline={content.headline} subline={content.subline}>
      <Autocomplete
        freeSolo
        clearOnBlur
        id="google-places"
        noOptionsText={content.noOptions}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        options={suggestions}
        autoHighlight
        onChange={(_event, newValue) => handleSelect(newValue)}
        onInputChange={(_event, newInputValue) => handleInputChange(newInputValue)}
        clearIcon={null}
        disabled={disabled}
        renderInput={(params) => (
          <TextField {...params} label={content.autocomplete} required variant="standard" fullWidth />
        )}
        renderOption={(props, suggestion) => (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <strong>{suggestion.structured_formatting.main_text}</strong>{' '}
                <span>{suggestion.structured_formatting.secondary_text}</span>
              </Grid>
            </Grid>
          </li>
        )}
      />
      {formState?.place?.coordinates && (
        <Section spacing="topMedium">
          <Map coordinates={formState?.place?.coordinates} bounds={formState?.place?.bounds} />
        </Section>
      )}
    </Section>
  )
}
