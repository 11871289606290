/** @jsxImportSource @emotion/react */
import {useMemo, useContext, useState, useEffect} from 'react'
import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import { isNil } from 'lodash'
import { FormikSelect, Section, Alert, Link } from '../../../../components'
import { FormContext } from '../../../../context/form-context'
import { useTileKindsApi } from '../../../../hooks/use-api'
import { CacheContext } from '../../../../context/cache-context'
import config from '../../../../../lib/config/de-at.config.json'
import Card from './card'
import { content } from './products.content'
import { locales } from '../../../../../lib/common/enums/locales'
import QuantityCalculatorModal from './quantity-calculator-modal'
import GuidelineModal from './guideline-modal'
import RequirementsModal from './requirements-modal'
import CoveringModal from './covering-modal'

const roofCoveringProps = config.roofTiles.properties.roofCovering
const tileProps = config.roofTiles.properties.tile
const guidlineProps = config.underRoof?.properties.guideline
const higherRequirementsProps = config.underRoof?.properties.higherRequirements

export default function Products({lowRoofPitch, ...props}) {
  const { formState } = useContext(FormContext)
  const { cache } = useContext(CacheContext)
  const formik = useFormikContext()
  useTileKindsApi({ enabled: !cache?.tiles })
  const [isQuantityModalOpen, setQuantityModalOpen] = useState(false)
  const [isGuidelineModalOpen, setGuidelineModalOpen] = useState(false)
  const [isRequirementsModalOpen, setRequirementsModalOpen] = useState(false)
  const [isCoveringModalOpen, setCoveringModalOpen] = useState(false)

  const tileOptions = useMemo(() => {
    const tiles = isNil(cache?.tiles) ? [] : cache.tiles
    // remove specific tiles from the available list
    const tilesFiltered = tiles.filter((tile) => !['ANTICO', 'AUREUS', 'GRATUS', 'MAXIMA', 'ROMANO', 'VIVA', 'GÖTEBORG', 'PROFIL Strangfalzziegel HARGITA'].includes(tile.id))
      .filter((tile) => !lowRoofPitch || tile.supportLowRoofPitch);
    return tilesFiltered.map((tile) => ({
      id: tile.id,
      desc: tile.name,
      value: tile,
    }))
  }, [cache?.tiles, lowRoofPitch])

  let guidlineItems = [...guidlineProps.items];

  if(lowRoofPitch) {
    guidlineItems = [guidlineProps.items[1]];
  }

  useEffect(() => {
    if(lowRoofPitch) {
      formik.setFieldValue(guidlineProps.id, guidlineProps.items[1].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lowRoofPitch]);

  /**
   * Parse roofCoveringOptions and reset roofCovering if the tile has changed
   */
  const roofCoveringOptions = useMemo(() => {
    if (formik?.values?.tile) {
      const tile = formik?.values?.tile
      const options = tile.clips
        .flatMap((clip) => clip.covering)
        .filter((element, index, array) => array.indexOf(element) === index)
        .map((coveringId) => roofCoveringProps.items[coveringId])
      if (options.length === 1) {
        formik.setFieldValue('roofCovering', options[0].id)
      } else {
        formik.setFieldValue('roofCovering', '', false)
      }
      return options
    } else {
      return []
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.tile])

  return (
    <div {...props}>
      <Section
        headline={content.headline}
        subline={
          <>
            <span>{content.subline.text[0]}</span>
            <Link onClick={() => setQuantityModalOpen(true)}> {content.subline.text[1]}</Link>{' '}
            <span>{content.subline.text[2]}</span>{' '}
            <Link onClick={() => setCoveringModalOpen(true)}> {content.subline.text[3]}</Link>{' '}
          </>
        }
      >
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid item xs={12} sm={6}>
            <FormikSelect
              formik={formik}
              name={tileProps.id}
              label={tileProps.desc}
              options={tileOptions}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikSelect
              formik={formik}
              name={roofCoveringProps.id}
              label={roofCoveringProps.desc}
              options={roofCoveringOptions}
              required
            />
          </Grid>
        </Grid>
      </Section>

      {formik?.values?.tile && (
        <Section spacing="topLarge">
          <Card tile={formik.values.tile} locale={formState.place.locale} />
        </Section>
      )}

      {formState.place.locale === locales.germany && (
        <Section
          spacing="topLarge"
          headline={content.headlineUnderRoof}
          subline={
            <>
              <span>{content.sublineUnderRoof.text[0]}</span>{' '}
              <Link onClick={() => setGuidelineModalOpen(true)}>
                {' '}
                {content.sublineUnderRoof.text[1]}
              </Link>{' '}
              <span>{content.sublineUnderRoof.text[2]}</span>{' '}
              <Link onClick={() => setRequirementsModalOpen(true)}>
                {' '}
                {content.sublineUnderRoof.text[3]}
              </Link>{' '}
            </>
          }
        >
          <Grid container spacing={{ xs: 2, sm: 4 }}>
            <Grid item xs={12} sm={6}>
              <FormikSelect
                formik={formik}
                name={guidlineProps.id}
                label={guidlineProps.desc}
                options={guidlineItems}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {formik?.values?.guideline !== 'CREATON Herstellerangabe DE' ? (
                <FormikSelect
                  formik={formik}
                  name={higherRequirementsProps.id}
                  label={higherRequirementsProps.desc}
                  options={higherRequirementsProps.items}
                  required
                />
              ) : (
                <FormikSelect
                  formik={formik}
                  name={higherRequirementsProps.id}
                  label={higherRequirementsProps.desc}
                  options={higherRequirementsProps.items}
                  disabled
                />
              )}
            </Grid>
          </Grid>
        </Section>
      )}

      {/* ALERTS */}
      {formik?.values?.roofCovering === roofCoveringProps.items.crown.id && (
        <Section spacing="topMedium">
          <Alert severity="info" title={content.alerts.crownCovering.text} />
        </Section>
      )}
      {formik?.values?.tile && !formik?.values?.tile?.supportSnowCalculation && (
        <Section spacing="topMedium">
          <Alert severity="warning" title={content.alerts.supportSnowCalculation.text} />
        </Section>
      )}

      {/* MODALS */}
      <QuantityCalculatorModal
        isOpen={isQuantityModalOpen}
        onClose={() => setQuantityModalOpen(false)}
      />
      <GuidelineModal isOpen={isGuidelineModalOpen} onClose={() => setGuidelineModalOpen(false)} />
      <RequirementsModal
        isOpen={isRequirementsModalOpen}
        onClose={() => setRequirementsModalOpen(false)}
      />
      <CoveringModal isOpen={isCoveringModalOpen} onClose={() => setCoveringModalOpen(false)} />
    </div>
  )
}
