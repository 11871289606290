export const content = {
  ui: {
    details: {
      text: 'Details',
      meta: 'Zum Dachziegel',
    },
    close: 'Schließen',
  },
  headline: 'Angaben zum Dachziegel oder Dachstein',
  headlineUnderRoof: 'Angaben zum Unterdach',
  subline: {
    text: [' Bitte beachten Sie die Hinweise zur ', 'Mengenberechnung', ' und zur ', 'Eindeckung'],
  },
  sublineUnderRoof: {
    text: [
      'Für die entsprechende Produktempfehlung benötigen wir Angaben zur',
      'Vorgabe',
      'und die Anzahl',
      'erhöhter Anforderungen.',
    ],
  },
  modalQuantityCalc: {
    headline: 'Mengenberechnung',
    item: {
      title: 'Mengenberechnung',
      text: 'Sie erhalten für die eingegebene Dachform und das Ziegel-/Dachsteinmodell eine Mengenberechnung auf Basis der mittleren Decklänge und Deckbreite des Modells. Diese Angaben sind ein Anhalt, jedoch keine detailierte Vorgabe für Ihre Bestellung!',
    },
  },
  modalCovering: {
    headline: 'Eindeckung',
    items: [
      {
        title: 'Reiheneindeckung',
        text: 'Bei der Reiheneindeckung wird der Deckwerkstoff, z. B. Tondachziegel oder Betondachsteine, in senkrechten Reihen verlegt, so dass die senkrechten Linien durchlaufen.',
        image: require('../../../../../lib/common/assets/images/reihendeckung.jpg'),
      },
      {
        title: 'Verbandeindeckung',
        text: 'Die Verbandeindeckung zeichnet sich durch eine versetzte Eindeckung des Dachwerkstoffs von Reihe zu Reihe aus. In der Optik der Eindeckung verlaufen die senkrechten Linien nicht durch, sondern um beispielsweise einen halben oder viertelten Deckwerkstoff versetzt.',
        image: require('../../../../../lib/common/assets/images/verbanddeckung.jpg'),
      },
      {
        title: 'Doppeldeckung',
        text: 'Bei der Doppeldeckung wird jede Ziegellage auf einer eigenen Dachlatte verlegt, und zwar so, dass die jeweils untere Lage um etwas mehr als die Hälfte überdeckt wird. Dadurch wird die Oberkante der ersten Lage noch vom unteren Ende der dritten Lage überdeckt.',
        image: require('../../../../../lib/common/assets/images/doppeldeckung.jpg'),
      },
      {
        title: 'Kronendeckung',
        text: 'Bei der Kronendeckung werden beide Ziegellagen auf derselben Dachlatte eingehängt, so dass die untere Lage nahezu vollständig von der oberen Lage abgedeckt wird. Die untere Lage heißt Lagerschicht. Die darauf liegende Deckschicht ist erforderlich, um die darunterliegende Fuge der Lagerschicht abzudecken.',
        image: require('../../../../../lib/common/assets/images/kronendeckung.jpg'),
      },
    ],
  },
  modalRequirements: {
    headline: 'Erhöhte Anforderungen sind:',
    itemWithTable: {
      title: "- große Sparrenlängen > 10 m gemäß Tabelle 1",
      tableDesc: "Tabelle 1: Erhöhte Anforderungen in Abhängigkeit unterschiedlicher Dachneigungen und Sparrenlängen",
      col1: "Dachneigung",
      col2: "Sparrenlänge",
      rows: [
        {
          col1: "≥ 10°",
          col2: "> 10,00 m"
        },
        {
          col1: "≥ 20°",
          col2: "> 10,50 m"
        },
        {
          col1: "≥ 30°",
          col2: "> 11,50 m"
        },
        {
          col1: "≥ 40°",
          col2: "> 13,00 m"
        }
      ],
      hint: "Eine objectspezifische Planung der Zusatzmaßnahme ist bei Sparrenlängen > 15 m erforderlich."
    },
    items: [
      {
        text: '- konzentrierter Wasserlauf auf Teilflächen des Daches, z.B. unterhalb von Regenfallrohren, Zusammenführungen von Kehlen o.ä.',
      },
      {
        text: '- besondere Dachflächen wie geschweifte Gauben, Tonnen- und Kegeldächer',
      },
      {
        text: '- schneereiche Gebiete (Schneelast ≥ 1,5 kN/m²)',
      },
      {
        text: '- windreiche Gebiete der Windlastzonen 4 oder Kamm- und Gipfellagen oder Schluchtenbildung',
      }
    ],
  },
  modalGuideline: {
    headline: 'Vorgabe/Norm',
    items: [
      {
        title: 'ZVDH Regelwerk DE',
        text: 'Auslegung der Zusatzmaßnahme/des Unterdaches erfolgt nach den Fachregeln für Dacheindeckungen mit Dachziegeln und Dachsteinen aus dem Regelwerk des ZVDH (Zentralverband des Deutschen Dachdeckerhandwerkes). Dieses Regelwerk besitzt die Gültigkeit als „allgemein anerkannte Regeln der Technik“.',
      },
      {
        title: 'CREATON Herstellerangaben DE ',
        text: 'Auslegung der Zusatzmaßnahme/des Unterdaches erfolgt gemäß den Vorgaben nach den CREATON Herstellerangaben für Deutschland. Diese Vorgaben können von nationalen Regelwerken (wie bspw. ZVDH Regelwerk DE) abweichen. Daher empfehlen wir Ihnen eine Abstimmung und schriftliche Einzelvereinbarung zwischen Ihnen als ausführendem Dachhandwerker und dem Endkunden bzw. Eigentümer der Immobilie. Diese dient der vertraglichen Absicherung beider Parteien, ergänzend zur Leistungsvereinbarung. Eine Vorlage einer solchen Einzelvereinbarungen finden Sie in den CREATON Musterschreiben zu jedem Tondachziegel- und Betondachsteinmodell auf unserer Homepage.',
      },
    ],
  },
  modalPitchTileInfo: {
    headline: 'Wichtige Information',
    item: {
      title: 'Wichtige Information',
      text: 'Dachneigungen unter 10 Grad sind nur nach CREATON Herstellerangabe mit den Ziegelmodellen "FUTURA", "PREMION" und  "SINFONIE" möglich.',
    },
  },
  alerts: {
    crownCovering: {
      text: 'Bei der Kronendeckung kann man die unten liegende Lagerschicht entweder mit einer Sturmklammer oder mit der Edelstahlschraube an der Traglattung befestigen. Die oben liegende Deckschicht wird mit einer Sturmklammer an der Lagerschicht befestigt. Das Endergebnis bezieht sich nur auf eine Schicht und muss entsprechend verdoppelt werden.',
    },
    supportSnowCalculation: {
      text: 'Für das ausgewählte Produkt kann ausschließlich eine Berechnung zur Windsogsicherung durchgeführt werden.',
    },
  },
}
