const gridContainer = (theme) => ({
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  img: {
    margin: `-${theme.spacing(0.6)} 0`,
  },
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    img: {
      margin: 0,
    },
  },
})

const gridItem = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

export const styles = {
  gridContainer,
  gridItem,
}
