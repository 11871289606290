import { useState } from 'react'
import { mapStyles } from '../../../../../lib/config/google-map-styles'
import useGoogle from '../../../../hooks/use-google'
import { useEffect } from 'react'
import MarkerSVG from '../../../../../lib/common/assets/images/icons/marker.svg'
import { styles } from './place.styles'

/**
 * Map Configuration
 */
const defaultOptions = {
  styles: mapStyles,
  disableDefaultUI: true,
  fullscreenControl: true,
  zoom: 8,
}

export default function Map({ coordinates, bounds, ...props }) {
  const [map, setMap] = useState()
  const [marker, setMarker] = useState()
  const { google } = useGoogle()

  useEffect(() => {
    const element = document.getElementById('google-map')
    if (google && !map && element) {
      const map = new google.maps.Map(element, { ...defaultOptions, center: coordinates })
      const marker = new google.maps.Marker({
        position: coordinates,
        map: map,
        icon: {
          url: MarkerSVG,
          scaledSize: new google.maps.Size(46, 46),
          anchor: new google.maps.Point(23, 42),
        },
      })
      setMap(map)
      setMarker(marker)
    }
  }, [google, map, coordinates])

  useEffect(() => {
    if (map && marker) {
      map.panTo(coordinates)
      map.fitBounds(bounds)
      marker.setPosition(coordinates)
    }
  }, [coordinates, bounds, map, marker])

  return (
    <div {...props}>
      <div id="google-map" style={styles.map} />
    </div>
  )
}
