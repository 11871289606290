export const content = {
  ui: {
    close: 'Schließen',
  },
  headline: 'Abmessungen',
  subline: {
    text: ['Zur Berechnung benötigen wir Angaben zur', 'des Gebäudes.'],
    link: 'Abmessung',
  },
  roofPitch: {
    lowRoofPitchWarning: 'Mit einer Dachneigung unter 10grad steht nur eine begrenzte Ziegelauswahl zur Verfügung.',
  },
  modal: {
    headline: 'Gebäude- und Dachmaße',
    items: [
      {
        title: 'Gebäudehöhe',
        text: 'Zur Berechnung wird die gesamte Höhe von der niedrigsten Stelle des Gebäudes bis zum höchsten Punkt des Daches benötigt. Beachten Sie dies insbesondere bei Hanglagen.',
      },
      {
        title: 'Dachfläche',
        text: 'Um die Dachflächen berechnen zu können, werden die Maße des Hauses benötigt. Die Giebelbreite und die Gebäude- bzw. Trauflänge sind inkl. der Dachüberstände anzugeben.',
      },
      {
        title: 'Dachneigung',
        text: 'Eine Berechnung ist für eine Dachneigung von 7° bis 75° möglich.'
      },
      {
        title: 'Aufbauhöhe Unterdach',
        text: 'Die Höhe von Unterdach bis Oberkante Traglattung ist in Millimeter (mm) anzugeben. Der Abstand darf max. 100 mm betragen.',
      },
    ],
  },
}
