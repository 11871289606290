import { createContext, useState, useEffect } from 'react'
import GoogleAnalytics from '../../lib/serivces/google-analytics.service'

/**
 * Provides global access to google-analytics.
 */
const AnalyticsContext = createContext()

const AnalyticsContextProvider = (props) => {
  const [analytics, setAnalytics] = useState()

  useEffect(() => {
    if (!analytics) {
      const analytics = new GoogleAnalytics()
      setAnalytics(analytics)
    }
  }, [analytics])

  return (
    <AnalyticsContext.Provider value={{ analytics }}>{props.children}</AnalyticsContext.Provider>
  )
}

export { AnalyticsContext, AnalyticsContextProvider }
