/** @jsxImportSource @emotion/react */
import { Alert as MuiAlert, AlertTitle, Typography } from '@mui/material'
import { styles } from './alert.styles'
import { content } from './alert.content'

export default function ValidationAlert({ title, children, error, ...props }) {
  let errorMsg = error;

  try {
    const errObj = JSON.parse(error);

    if(errObj && errObj.message) {
      errorMsg = errObj.message;
    }
  } catch (e) {
    // ignore
  }

  return (
    <MuiAlert variant="filled" {...props}>
      <AlertTitle>{title}</AlertTitle>
      {children && <div css={error && styles.details}>{children}</div>}
      {error && (
        <>
          <Typography variant="body2">{content.validationError}</Typography>
          <Typography variant="body2">{errorMsg}</Typography>
        </>
      )}
    </MuiAlert>
  )
}
