const list = (theme) => ({
  margin: 0,
  paddingLeft: theme.spacing(4),
})

const listItem = (theme) => ({ margin: `${theme.spacing(1)} 0` })

const section = (theme) => ({
  backgroundColor: theme.palette.info.light,
  margin: `${theme.spacing(8)} 0`,
  padding: theme.spacing(4),
})

export const styles = {
  list,
  listItem,
  section,
}
