/** @jsxImportSource @emotion/react */
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from '@mui/material'
import { content } from './snowload.content'
import { styles } from './snowload.styles'

export default function SnowZoneModal({ locale, isOpen, onClose, ...props }) {
  const modal = content[locale].modals.snowZone

  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpen} onClose={onClose} {...props}>
      <DialogTitle>{modal.headline}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>{modal.text}</Typography>
          <img css={styles.map} src={modal.image} alt={modal.text} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{content.ui.close}</Button>
      </DialogActions>
    </Dialog>
  )
}
