const wrapper = {
  display: 'flex',
  justifyContent: 'flex-end',
}
const submit = {
  marginLeft: '15px',
}

export const styles = {
  wrapper,
  submit,
}
