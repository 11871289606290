/** @jsxImportSource @emotion/react */
import { Link as MuiLink } from '@mui/material'
import { useState } from 'react'
import { Button, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Cloud, Section, Link } from '../../components'
import creatonLogo from '../../../lib/common/assets/images/creaton-logo.png'
import { styles } from './landing-page.styles'
import { useTileKindsApi } from '../../hooks/use-api'
import { content } from './landing-page.content'
import CalculationInfoModal from '../../containers/landing-page/calculation-info-modal'

export default function LandingPage() {
  const navigate = useNavigate()
  const [hasConfirmed, setConfirmed] = useState(false)
  const [hasConfirmedAgb, setConfirmedAgb] = useState(false)
  const [isInfoModalOpen, setInfoModalOpen] = useState(false)

  /**
   * Preload all tiles
   */
  useTileKindsApi({ enabled: true })

  const handleChange = () => {
    setConfirmed(!hasConfirmed)
  }

  const handleChangeAgb = () => {
    setConfirmedAgb(!hasConfirmedAgb)
  }

  const startCalculation = () => {
    navigate('/tool')
  }

  return (
    <>
      <img css={styles.logo} src={creatonLogo} alt={content.logo} />
      <div css={styles.page}>
        <Cloud css={styles.cloud} text={content.headline} />
        <div css={styles.card}>
          <Typography variant="body1">{content.info}</Typography>
          <Section spacing="topSmall">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={hasConfirmed} onChange={handleChange} />}
                label={
                  <>
                    <span>{content.checkbox.text[0]}</span>{' '}
                    <Link onClick={() => setInfoModalOpen(true)}>{content.checkbox.link}</Link>{' '}
                    <span>{content.checkbox.text[1]}</span>
                  </>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={hasConfirmedAgb} onChange={handleChangeAgb} />}
                css={{ marginTop: '1rem' }}
                label={
                  <>
                    <span>{content.checkbox_agb.text[0]}</span>{' '}
                    <MuiLink href='https://www.creaton.de/datenschutzerklaerung' target='_blank' rel='noopener'>{content.checkbox_agb.link}</MuiLink>{' '}
                    <span>{content.checkbox_agb.text[1]}</span>
                  </>
                }
              />
            </FormGroup>
          </Section>

          <Button
            css={styles.button}
            onClick={startCalculation}
            variant="contained"
            disabled={!hasConfirmed || !hasConfirmedAgb}
            fullWidth
            disableElevation
          >
            {content.action}
          </Button>
        </div>

        {/* MODALS */}
        <CalculationInfoModal isOpen={isInfoModalOpen} onClose={() => setInfoModalOpen(false)} />
      </div>
    </>
  )
}
