import { createContext, useState, useEffect } from 'react'
import { loader } from '../../lib/config/google-api-loader'

/**
 * Provides global access to google-api.
 */
const GoogleApiContext = createContext()

const GoogleApiProvider = (props) => {
  const [google, setGoogle] = useState()

  useEffect(() => {
    loader
      .load()
      .then((google) => setGoogle(google))
      .catch((error) => {
        throw Error('[Google-Api-Context] Google Api could not be loaded!', error)
      })
  }, [])

  return <GoogleApiContext.Provider value={{ google }}>{props.children}</GoogleApiContext.Provider>
}

export { GoogleApiContext, GoogleApiProvider }
