/** @jsxImportSource @emotion/react */
import { useFormikContext } from 'formik'
import { Grid, Link } from '@mui/material'
import {Section, FormikTextField, FormikSelect, FormikCheckbox} from '../../../../components'
import config from '../../../../../lib/config/de-at.config.json'
import { content } from './pdf.content'
import { styles } from './pdf.styles'

const targetGroupProps = config.result.properties.targetGroup
const emailProps = config.result.properties.email
const nameProps = config.result.properties.name
const legalHintProps = config.result.properties.legalHints

export default function PDF(props) {
  const formik = useFormikContext()
  return (
    <div {...props}>
      <Section
        spacing="topLarge"
        headline={content.headline}
        subline={
          <>
            <span>{content.subline.text[0]}</span>{' '}
            <Link
              target="_blank"
              rel="noopener"
              href={content.subline.link.href}
              title={content.subline.link.meta}
            >
              {content.subline.link.text}
            </Link>
            {'. '}
            <span>{content.subline.text[1]}</span>
            <ul css={styles.list}>
              {content.subline.items.map((item, index) => (
                <li key={`pdf-${index}`} css={styles.listItem}>
                  {item}
                </li>
              ))}
            </ul>
          </>
        }
      />
      <Section headline={content.legalHints.headline}
               subline={content.legalHints.subline}
               css={styles.section}
      >
        <Grid container spacing={{xs: 2, sm: 4}}>
          <Grid item xs={12}>
            <FormikCheckbox
              formik={formik}
              name={legalHintProps.id}
              label={legalHintProps.desc}
            />
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid item xs={12} sm={6}>
            <FormikTextField formik={formik} name={emailProps.id} label={emailProps.desc} required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikTextField formik={formik} name={nameProps.id} label={nameProps.desc} required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikSelect
              formik={formik}
              name={targetGroupProps.id}
              label={targetGroupProps.desc}
              options={targetGroupProps.items}
              required
            />
          </Grid>
        </Grid>
      </Section>
    </div>
  )
}
