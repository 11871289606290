/** @jsxImportSource @emotion/react */
import { useContext } from 'react'
import { Grid, Card, Typography } from '@mui/material'
import { Section } from '../../../../components'
import { CacheContext } from '../../../../context/cache-context'
import { content } from './snownose.content'
import { styles } from '../step5.styles'

export default function SnowNose(props) {
  const { cache } = useContext(CacheContext)

  return (
    <div {...props}>
      <Section spacing="topLarge" headline={content.headline}>
        <Card elevation={3}>
          <Grid container>
            <Grid item p={2} xs={12} sm={6} css={styles.gridItem}>
              <Typography>
                {cache?.snowLoadProtection?.snowNoses} {content.snowNoses[1]}
              </Typography>
              <Typography variant="caption">{content.snowNoses[0]}</Typography>
            </Grid>
            <Grid
              item
              p={2}
              xs={12}
              sm={6}
              css={(theme) => ({ ...styles.gridItem, background: theme.palette.grey[50] })}
            >
              <Typography>
                {cache?.snowLoadProtection?.snowNosesSquareMetre} {content.snowNosesSquareMetre[1]}
              </Typography>
              <Typography variant="caption">{content.snowNosesSquareMetre[0]}</Typography>
            </Grid>
          </Grid>
        </Card>
      </Section>
    </div>
  )
}
